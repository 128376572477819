import React from "react";
import Layout from "./Layout";
import Login from "./Components/Auth/Login/Login";
import ForgotPassword from "./Components/Auth/Forgot-password";
import ResetPassword from "./Components/Auth/Reset-password";
import history from "./History/history";
import PublicRoute from "./Routes/public-route";
import { Switch, Router } from "react-router-dom";
import {
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  RESET_PASSWORD_ROUTE,
  DEMO_FORM,
  SIGNUP_ROUTE,
  NEW_USER_UPLOAD_ROUTE,
  SAMPLE_DASHBOARD_ROUTE
} from "./Constants/routeNames";
import { Provider } from "react-redux";
import { ConfigureStore } from "./store";
import { getBackendGitCommit } from "./actions/Creators";
import GitInfo from "react-git-info/macro";
import DemoForm from "./Components/DemoFields/DemoForm";
import SignUp from "./Components/Auth/Signup/Sign-up";
import NewUserUpload from "./Components/Auth/NewUserUpload/User-upload";
import SampleDashboard from "./Components/Auth/sample-dashboard";

const PUBLIC_ROUTES = [
  {
    id: 1,
    component: <Login />,
    path: LOGIN_ROUTE,
    exact: true,
  },
  {
    id: 2,
    component: <ForgotPassword />,
    path: FORGOT_PASSWORD_ROUTE,
    exact: true,
  },
  {
    id: 3,
    component: <ResetPassword />,
    path: RESET_PASSWORD_ROUTE,
    exact: true,
  },
  {
    id: 4,
    component: <DemoForm />,
    path: DEMO_FORM,
    exact: true,
  },
  {
    id: 5,
    component: <SignUp />,
    path: SIGNUP_ROUTE,
    exact: true,
  },
  {
    id: 6,
    component: <NewUserUpload />,
    path: NEW_USER_UPLOAD_ROUTE,
    exact: true,
  },
  {
    id: 7,
    component: <SampleDashboard />,
    path: SAMPLE_DASHBOARD_ROUTE,
    exact: true,
  },
];

function App() {
  const gitInfo = GitInfo();
  ConfigureStore.dispatch(getBackendGitCommit());
  console.log(`f:${gitInfo.commit.shortHash}`);

  return (
    <Provider store={ConfigureStore}>
      <Router history={history}>
        <Switch>
          {/* Public Routes */}
          {PUBLIC_ROUTES.map((d) => (
            <PublicRoute path={d.path} key={d.id} exact={d.exact}>
              {d.component}
            </PublicRoute>
          ))}
        </Switch>
        <Layout />
      </Router>
    </Provider>
  );
}

export default App;
