import React, { useState } from "react";
import { Row, Col, Button } from "antd";
import NoFilesChoosen from "../../Common/NoFilesChoosen/NoFilesChoosen";
import UploadInvoiceList from "../../Components/Invoice/UploadInvoiceList";
import Uploader from "../../Common/Uploader";

const UploadInvoice = () => {
  const [files, setFiles] = useState([]);
  const acceptedFormats = ["pdf"];
  const setFilteredFiles = (files) => {
    setFiles(files.filter((file) => acceptedFormats.includes(file.name.split(".").reverse()[0])));
  };
  const onRemoveFile = (key) => {
    setFiles(files.filter((file, index) => index !== key));
  };
  return (
    <div>
      <Row className="content-header">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <h3>Upload Invoice</h3>
          <Uploader files={files} setFiles={(files) => setFilteredFiles(files)} type="Invoice files." accept={".pdf"} />
        </Col>
      </Row>
      {files.length > 0 ? <UploadInvoiceList files={files} removeFile={onRemoveFile} /> : <NoFilesChoosen />}
    </div>
  );
};

export default UploadInvoice;
