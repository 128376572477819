import React from "react";
import { AutoComplete, Select, Form } from "antd";
import "./uploader.scss";

const Dropdown = ({ disabled, type, setType, optionList }) => {
  const { Option } = Select;

  return (
    <Select disabled={disabled} placeholder="File Type" showSearch={true} onChange={(value, opt) => setType(value)} value={type} className="upload-dropdown">
      {optionList.map((option) => (
        <Option key={option.id} value={option.title}>
          {option.title}
        </Option>
      ))}
    </Select>
  );
};

export default Dropdown;
