import { Col, Row, DatePicker } from "antd";
import React from "react";
import moment from "moment";

const PricingSheetInput = ({ setPricingData, pricingData }) => {
  const onChange = (date, dateString, key) => {
    setPricingData((prevDates) => ({
      ...prevDates,
      [key]: moment(date).format(),
    }));
  };
  return (
    <div className="mb-25">
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <DatePicker onChange={(date, dateString) => onChange(date, dateString, "issue_date")} placeholder="Select Issue Date" />
        </Col>
        <Col span={6}>
          <DatePicker onChange={(date, dateString) => onChange(date, dateString, "effective_date")} placeholder="Select Effective Date" />
        </Col>
      </Row>
    </div>
  );
};

export default PricingSheetInput;
