import React, { useState, useEffect } from "react";
import moment from "moment";
import { Row, Col, Table, Badge, Button, DatePicker, Input, Select, Tooltip } from "antd";
import { Link, useLocation } from "react-router-dom";
import { CommentOutlined, DeleteOutlined, HistoryOutlined } from "@ant-design/icons";
import Notification from "../../Common/Notification";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Common/Loader";
import { HIDE_NOTIFICATION } from "../../actions/Types";
import { actionDispatch, getPOs, deletePO, getVendors, setPagination, setRedirectStatus, getComments } from "../../actions/Creators";
import { UPLOAD_PO_ROUTE, ADD_PO_ROUTE, PO_DISCREPANCY_APPROVER_ROUTE } from "../../Constants/routeNames";
import Modal from "../../Common/Modal";
import Authorized from "../../Role-Authorization/Authorized";
import { ROLES_DISCREPANCY_APPROVER_VIEW, ROLES_PO_ADD_VIEW } from "../../utils/constants";
import CommentsData from "../../Common/CommentsCard";
import { isAuthorized } from "../../Services/Auth/auth";
import { dateToLocal } from "../../utils/moment";
import "./analytics.scss";

const SKUAnalytics = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const pagination = useSelector((state) => state.pagination.po);
  const comments = useSelector((state) => state.commentsHistory);
  const [isCommentsOpen, setIsCommentsOpen] = useState(false);
  const [isPaginationSet, setIsPaginationSet] = useState(pagination.isRedirectedFromChild);
  const rolesArr = useSelector((state) => state.auth.roles);
  const [page, setPage] = useState(pagination.page && isPaginationSet ? pagination.page : 1);
  const [params, setParams] = useState({
    limit: pagination.limit && isPaginationSet ? pagination.limit : 10,
    skip: pagination.page && isPaginationSet ? pagination.page * pagination.limit - pagination.limit : 0,
    searchText: "",
    minAmount: null,
    maxAmount: null,
    startDate: "",
    endDate: "",
    status: null,
  });
  const [isModalOpen, setIsOpenModal] = useState({
    state: false,
    id: null,
  });
  const po = useSelector((state) => state.po);
  const vendor = useSelector((state) => state.vendor);
  const organization = useSelector((state) => state.header.selectedOrganization);
  const { Option } = Select;

  const columns = [
    {
      title: "#",
      dataIndex: "serialNumber",
      width: 60,
      height: 100,
      align: "center",
      render: (text, object, index) => (page - 1) * params.limit + (index + 1),
    },
    {
      title: "Vendor",
      dataIndex: "vendor_name",
      width: 200,
      ellipsis: true,
    },
    {
      title: "Unit Price",
      dataIndex: "unit_price",
      render: (amount) =>
        Number(amount) || Number(amount) == 0
          ? `$${Number(amount)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
          : "N/A",
      width: 100,
      className: "bold-im",
    },
    {
      title: "Tier Pricing",
      dataIndex: "tier_pricing",
      width: 100,
    },

    {
      title: "Volume Discounts",
      dataIndex: "volume_discounts",
      className: "bold-im",
      width: 100,
    },
    {
      title: "Contract Number",
      dataIndex: "contract_number",
      className: "bold-im",
      width: 150,
    },
    {
      title: "Contract Expiry Date",
      dataIndex: "createdAt",
      render: (date) => dateToLocal(date),
      width: 150,
    },
  ];

  const onTabChange = (pageNo, pageSize) => {
    setPage(pageNo);
    setParams({
      ...params,
      limit: pageSize,
      skip: pageNo * pageSize - pageSize,
    });
  };

  const notification = useSelector((state) => state.commonReducer.notification);
  const loading = useSelector((state) => state.commonReducer.loader);

  const tableLoading = {
    spinning: loading,
    indicator: <Loader />,
  };

  return (
    <>
      {notification.open && notification.message ? (
        <Notification
          msg={notification.message}
          type={notification.type}
          clearMsg={() => {
            dispatch(actionDispatch(HIDE_NOTIFICATION));
          }}
        />
      ) : null}

      <Row className="content-header">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <h2>SKU Analytics</h2>
        </Col>
      </Row>
      <Row className="filter-wrapper" gutter={[24, 12]} align="top">
        <Col xs={12} sm={12} md={6} lg={10} xl={10} xxl={5}>
          <Input placeholder="Search..." name="searchText" onChange={() => {}} value={params.searchText} prefix={<span class="icon-search"></span>} />
        </Col>

        <Button className="btn-orange">
          {" "}
          <span className="icon-search-white mr-5 "></span>Search
        </Button>
      </Row>

      <Table
        columns={columns}
        loading={tableLoading}
        dataSource={[
          {
            key: "1",
            vendor_name: "Jeff Corp",
            unit_price: "60",
            tier_pricing: "Yes",
            volume_discounts: "Yes",
            contract_number: "C1235",
          },
          {
            key: "2",
            vendor_name: "Block-Waters",
            unit_price: "370",
            tier_pricing: "No",
            volume_discounts: "No",
            contract_number: "CX235",
          },
          {
            key: "3",
            vendor_name: "Wuckert-Terry",
            unit_price: "400",
            tier_pricing: "No",
            volume_discounts: "Yes",
            contract_number: "C1Y235",
          },
          {
            key: "4",
            vendor_name: "Zieme",
            unit_price: "491",
            tier_pricing: "Yes",
            volume_discounts: "No",
            contract_number: "CK235",
          },
          {
            key: "5",
            vendor_name: "Niel James",
            unit_price: "600",
            tier_pricing: "Yes",
            volume_discounts: "Yes",
            contract_number: "C1235",
          },
          {
            key: "6",
            vendor_name: "Supply Force",
            unit_price: "562",
            tier_pricing: "Yes",
            volume_discounts: "Yes",
            contract_number: "C1235",
          },
        ]}
        pagination={{
          defaultPageSize: params.limit,
          position: ["none", "bottomCenter"],
          total: po && po.pos && po.pos.count,
          pageSizeOptions: ["10", "15", "20"],
          locale: { items_per_page: "" },
          showTotal: () => `Showing ${params.skip + 1} - ${params.limit * page} of ${po.pos.count} records.`,
          showSizeChanger: true,
          current: page,
          onChange: (page, pageSize) => onTabChange(page, pageSize),
        }}
      />
    </>
  );
};

export default SKUAnalytics;
