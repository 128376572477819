import React, { useState, useEffect } from "react";
import moment from "moment";
import { Row, Col, Table, Badge, Button, DatePicker, Input, Select, Tooltip } from "antd";
import { Link, useLocation, useHistory } from "react-router-dom";
import { CommentOutlined, DeleteOutlined, HistoryOutlined } from "@ant-design/icons";
import Notification from "../../Common/Notification";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Common/Loader";
import { HIDE_NOTIFICATION } from "../../actions/Types";
import { actionDispatch, getPOs, deletePO, getVendors, setPagination, setRedirectStatus, getComments } from "../../actions/Creators";
import { UPLOAD_PO_ROUTE, ADD_PO_ROUTE, PO_DISCREPANCY_APPROVER_ROUTE, VIEW_PO_ROUTE } from "../../Constants/routeNames";
import { Status, badgeColor, searchFilter } from "./POStatus";
import Modal from "../../Common/Modal";
import Authorized from "../../Role-Authorization/Authorized";
import { ROLES_DISCREPANCY_APPROVER_VIEW, ROLES_PO_ADD_VIEW } from "../../utils/constants";
import CommentsData from "../../Common/CommentsCard";
import { isAuthorized } from "../../Services/Auth/auth";
import { dateToLocal } from "../../utils/moment";

const POList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const pagination = useSelector((state) => state.pagination.po);
  const comments = useSelector((state) => state.commentsHistory);
  const [isCommentsOpen, setIsCommentsOpen] = useState(false);
  const [isPaginationSet, setIsPaginationSet] = useState(pagination.isRedirectedFromChild);
  const rolesArr = useSelector((state) => state.auth.roles);

  const [searchFilterDropDown, setSearchFilterDropDown] = useState("searchText");

  const [page, setPage] = useState(pagination.page && isPaginationSet ? pagination.page : 1);
  const [params, setParams] = useState({
    limit: pagination.limit && isPaginationSet ? pagination.limit : 10,
    skip: pagination.page && isPaginationSet ? pagination.page * pagination.limit - pagination.limit : 0,
    [searchFilterDropDown]: "",
    minAmount: null,
    maxAmount: null,
    startDate: "",
    endDate: "",
    status: null,
    customer_location: "",
  });

  const [isModalOpen, setIsOpenModal] = useState({
    state: false,
    id: null,
  });
  const po = useSelector((state) => state.po);
  const vendor = useSelector((state) => state.vendor);
  const organization = useSelector((state) => state.header.selectedOrganization);
  const { Option } = Select;

  useEffect(() => {
    dispatch(getVendors(organization.id));
  }, []);

  useEffect(() => {
    dispatch(setRedirectStatus(false, "po"));
    if (isAuthorized(ROLES_DISCREPANCY_APPROVER_VIEW, rolesArr, ROLES_PO_ADD_VIEW[0])) {
      dispatch(getPOs(organization.id, params, true));
    } else {
      dispatch(getPOs(organization.id, params, false));
    }
    dispatch(
      setPagination(
        {
          page: page,
          limit: params.limit,
        },
        "po"
      )
    );
  }, [getPOs, params]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const filter = queryParams.get("filter");
    const customer_location = queryParams.get("location");

    if (filter) {
      const startDate = moment().subtract(1, "years").format("YYYY-MM-DD");
      const endDate = moment(new Date()).format("YYYY-MM-DD");
      setParams({
        ...params,
        skip: 0,
        startDate,
        endDate,
      });
      setPage(1);
    }
    if (customer_location) {
      setParams({
        ...params,
        skip: 0,
        customer_location,
      });
      setPage(1);
    }
  }, []);

  const onChangeHandler = (e) => {
    setParams({
      ...params,
      skip: 0,
      [e.target.name]: e.target.value,
    });
    setPage(1);
  };

  const onClearFilters = () => {
    setPage(1);
    setParams({
      ...params,
      skip: 0,
      searchText: "",
      minAmount: null,
      maxAmount: null,
      startDate: "",
      endDate: "",
      status: null,
      customer_location: "",
    });
    if (params?.customer_location) {
      history.replace(VIEW_PO_ROUTE);
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "serialNumber",
      // width: 60,
      align: "center",
      render: (text, object, index) => (page - 1) * params.limit + (index + 1),
      // responsive: ["xs", "md", "lg", "xl"],
      // fixed: "left",
    },
    {
      title: "Vendor Name",
      dataIndex: "vendor_id",
      // width: 140,
      ellipsis: true,
      render: (vendor_id) => vendor && vendor.vendors.filter((vendor) => vendor_id === vendor.id).map((vendor) => vendor.title),
      // responsive: ["xs", "md", "lg", "xl"],
      // fixed: "left",
    },
    {
      title: "PO No.",
      dataIndex: "po_number",
      render: (po_number) => (po_number ? po_number : "N/A"),
      ellipsis: true,
      // width: 180,
      // responsive: ["xs", "md", "lg", "xl"],
      // fixed: "left",
    },
    {
      title: "Contract Associated",
      dataIndex: "contract_associate",
      ellipsis: true,
      render: (contract_associate) => (contract_associate ? contract_associate : "N/A"),
      // responsive: ["xs", "md", "lg", "xl"],
      // fixed: "left",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (amount) =>
        amount || amount == 0
          ? `$${amount
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
          : "N/A",
      // width: 180,
      className: "bold-im",
      // responsive: ["xs", "md", "lg", "xl"],
      // fixed: "left",
    },
    {
      title: "Discrepancies",
      dataIndex: "discrepancyCount",
      render: (discrepancy) => (discrepancy ? (discrepancy < 10 ? `0${discrepancy}` : discrepancy) : "N/A"),
      // width: 160,
      className: "bold-im",
      // responsive: ["xs", "md", "lg", "xl"],
      // fixed: "left",
    },
    {
      title: "Added On",
      // width: 160,
      dataIndex: "createdAt",
      render: (date) => dateToLocal(date),
      // responsive: ["xs", "md", "lg", "xl"],
      // fixed: "left",
    },
    {
      title: "Status",
      dataIndex: "status",
      // width: 240,
      render: (text) => {
        let color;
        for (let prop in badgeColor)
          if (prop === text) {
            color = badgeColor[prop];
          }
        return (
          <div style={{ textTransform: "capitalize" }}>
            <Badge color={color} />
            {text}
          </div>
        );
      },
      // responsive: ["xs", "md", "lg", "xl"],
      // fixed: "left",
    },
    {
      title: "Actions",
      dataIndex: "view",
      // width: 124,
      align: "center",
      align: "left",
      render: (text, row) => {
        return (
          <Row align="middle" justify="left">
            <Authorized roleList={ROLES_DISCREPANCY_APPROVER_VIEW}>
              <Col span={8}>
                <Tooltip title="View Discrepancy PO">
                  <Link className="clr-link" to={`${PO_DISCREPANCY_APPROVER_ROUTE}${row.id}`}>
                    <span className="icon-row-view" onClick={() => dispatch(setRedirectStatus(true, "po"))}></span>
                  </Link>
                </Tooltip>
              </Col>
            </Authorized>
            <Authorized roleList={ROLES_PO_ADD_VIEW}>
              <Col span={8}>
                <Tooltip title="View PO">
                  <Link className="clr-link" to={`${ADD_PO_ROUTE}${row.id}`}>
                    <span className="icon-row-view" onClick={() => dispatch(setRedirectStatus(true, "po"))}></span>
                  </Link>
                </Tooltip>
              </Col>
            </Authorized>
            <Col span={8}>
              <Tooltip title="Show history">
                <HistoryOutlined className="clr-link clr-link" onClick={() => showComments(row.id)} />
              </Tooltip>
            </Col>
            {row.status === Status.PENDING && (
              <Col span={8}>
                <Tooltip title="Delete PO">
                  <DeleteOutlined
                    className="clr-link"
                    onClick={() =>
                      setIsOpenModal({
                        state: true,
                        id: row.id,
                      })
                    }
                  />
                </Tooltip>
              </Col>
            )}
          </Row>
        );
      },
      // responsive: ["xs", "md", "lg", "xl"],
      // fixed: "right",
    },
  ];

  const onTabChange = (pageNo, pageSize) => {
    setPage(pageNo);
    setParams({
      ...params,
      limit: pageSize,
      skip: pageNo * pageSize - pageSize,
    });
  };

  const notification = useSelector((state) => state.commonReducer.notification);
  const loading = useSelector((state) => state.commonReducer.loader);
  const onRemove = async (id) => {
    await dispatch(deletePO(id, po.pos));
    if (po.pos.rows.length < 2) {
      setPage(page - 1);
      setParams({
        ...params,
        skip: (page - 1) * params.limit - params.limit,
      });
    } else dispatch(getPOs(organization.id, params, false));
    setIsOpenModal({ state: false, id: null });
  };
  const tableLoading = {
    spinning: loading,
    indicator: <Loader />,
  };

  const showComments = (row_id) => {
    dispatch(
      getComments(organization.id, {
        entity_id: row_id,
        entity_type: "po",
      })
    );
    setIsCommentsOpen(true);
  };

  return (
    <>
      {notification.open && notification.message ? (
        <Notification
          msg={notification.message}
          type={notification.type}
          clearMsg={() => {
            dispatch(actionDispatch(HIDE_NOTIFICATION));
          }}
        />
      ) : null}
      {isModalOpen && (
        <Modal isOpen={isModalOpen.state} handleOk={() => onRemove(isModalOpen.id)} setParentClose={() => setIsOpenModal({ state: false, id: null })}>
          Are you sure you want to delete this invoice?
        </Modal>
      )}
      {isCommentsOpen && (
        <Modal width={1400} loading={loading} details="Purchase order history" showFooterButtons={false} isOpen={isCommentsOpen} setParentClose={() => setIsCommentsOpen(false)}>
          <CommentsData data={comments && comments.comments} />
        </Modal>
      )}
      <Row className="content-header">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <h2>
            Purchase Orders{" "}
            {new URLSearchParams(location.search).get("location")
              ? ` - ${new URLSearchParams(location.search).get("location")}`
              : searchFilterDropDown === "customer_location"
              ? params?.[searchFilterDropDown]
                ? ` - ${params?.[searchFilterDropDown]}`
                : ""
              : ""}
          </h2>
        </Col>
        <Authorized roleList={ROLES_PO_ADD_VIEW}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="d-flex f-justify-end">
            <Link to={UPLOAD_PO_ROUTE}>
              <Button className="btn-primary">
                {" "}
                <span className="btn-icon icon-upload"></span>Add New
              </Button>
            </Link>
          </Col>
        </Authorized>
      </Row>
      <Row className="filter-wrapper" gutter={[24, 12]} align="top">
        <Col xs={12} sm={12} md={6} lg={10} xl={10} xxl={5}>
          <Input placeholder="Search..." name={searchFilterDropDown} onChange={onChangeHandler} value={params?.[searchFilterDropDown]} prefix={<span class="icon-search"></span>} />
        </Col>

        <Col xs={12} sm={12} md={4} lg={7} xl={7} xxl={3}>
          <Select
            onChange={(value) => {
              setParams({
                ...params,
                skip: 0,
                [searchFilterDropDown]: "",
              });
              setSearchFilterDropDown(value);
            }}
            name="Filter"
            placeholder="All"
            value={searchFilterDropDown}
          >
            <Option value={searchFilter.name}>PO No/Vendor Name</Option>
            <Option value={searchFilter.site}>Site Name</Option>
          </Select>
        </Col>

        <Col xs={12} sm={12} md={6} lg={7} xl={7} xxl={3}>
          <Input placeholder="Min amount" name="minAmount" onChange={onChangeHandler} value={params.minAmount} />
        </Col>
        <Col xs={12} sm={12} md={6} lg={7} xl={7} xxl={3}>
          <Input placeholder="Max amount" name="maxAmount" onChange={onChangeHandler} value={params.maxAmount} />
        </Col>
        <Col xs={12} sm={12} md={4} lg={10} xl={10} xxl={7}>
          <DatePicker.RangePicker
            placeholder={["YYYY-MM-DD", "YYYY-MM-DD"]}
            name="dateRange"
            allowClear={false}
            suffixIcon={<span class="icon-calendar"></span>}
            allowEmpty={[false, true]}
            onChange={(date) => {
              setParams({
                ...params,
                skip: 0,
                startDate: moment(date[0]).format("YYYY-MM-DD"),
                endDate: date[1] ? moment(date[1]).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
              });
              setPage(1);
            }}
            value={[params.startDate ? moment(params.startDate) : "", params.endDate ? moment(params.endDate) : ""]}
          />
        </Col>
        <Authorized roleList={ROLES_DISCREPANCY_APPROVER_VIEW} shouldNotIncludePersona={ROLES_PO_ADD_VIEW}>
          <Col xs={12} sm={12} md={4} lg={7} xl={7} xxl={3}>
            <Select
              onChange={(value) => {
                setParams({
                  ...params,
                  skip: 0,
                  status: value,
                });
                setPage(1);
              }}
              name="status"
              placeholder="All"
              value={params.status}
            >
              <Option value={Status.DISCREPANCY_APPROVAL}>POs W/ Disc</Option>
              <Option value={Status.APPROVED_WITH_DISCREPANCY}>Approved W/ Disc</Option>
              <Option value={Status.REJECTED}>Rejected</Option>
            </Select>
          </Col>
        </Authorized>
        <Authorized roleList={ROLES_PO_ADD_VIEW}>
          <Col xs={12} sm={12} md={4} lg={7} xl={7} xxl={3}>
            <Select
              onChange={(value) => {
                setParams({
                  ...params,
                  skip: 0,
                  status: value,
                });
                setPage(1);
              }}
              name="status"
              placeholder="All"
              value={params.status}
            >
              <Option value={Status.PENDING}>Pending</Option>
              <Option value={Status.IN_PROCESS}>In-process</Option>
              <Option value={Status.PROCESSED}>Processed</Option>
              <Option value={Status.DISCREPANCY_PENDING}>Disc pending</Option>
              <Option value={Status.DISCREPANCY_APPROVAL}>Disc approval</Option>
              <Option value={Status.CONFIRMED}>Confirmed w/o Disc</Option>
              <Option value={Status.APPROVED_WITH_DISCREPANCY}>Approved w/ Disc</Option>
              <Option value={Status.REJECTED}>Rejected</Option>
              <Option value={Status.TEMPLATE_NOT_SUPPORTED}>Template not supported</Option>

              {/* <Option value={Status.INVOICED}>Invoiced</Option>
              <Option value={Status.APPROVED_WITHOUT_CONTRACT}>
                Approved w/o contract
              </Option>
              <Option value={Status.DRAFT}>Draft</Option>
              <Option value={Status.CLOSED}>Closed</Option> */}
            </Select>
          </Col>
        </Authorized>
        <Col xs={12} sm={12} md={3} lg={7} xl={7} xl={3}>
          <Button type="link" className="btb-clear-filter" onClick={onClearFilters}>
            <span class="btn-icon icon-cross"></span> Clear Filters
          </Button>
        </Col>
      </Row>

      <Table
        columns={columns}
        loading={tableLoading}
        scroll={{ x: 800 }}
        dataSource={po && po.pos && po.pos.rows}
        pagination={{
          defaultPageSize: params.limit,
          position: ["none", "bottomCenter"],
          total: po && po.pos && po.pos.count,
          pageSizeOptions: ["10", "15", "20"],
          locale: { items_per_page: "" },
          showTotal: () => `Showing ${params.skip + 1} - ${params.limit * page} of ${po.pos.count} records.`,
          showSizeChanger: true,
          current: page,
          onChange: (page, pageSize) => onTabChange(page, pageSize),
        }}
      />
    </>
  );
};

export default POList;
