import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SidePanel from "../SidePanel";
import { Form, Button, Typography, Row, Col, Table } from "antd";
import { Link } from "react-router-dom";
import Notification from "../../../Common/Notification";
import { actionDispatch } from "../../../actions/Creators";
import { HIDE_NOTIFICATION } from "../../../actions/Types";
import { ArrowLeftOutlined, DeleteOutlined } from "@ant-design/icons";
import NoFilesChoosen from "../../../Common/NoFilesChoosen/NoFilesChoosen";
import { uploadNewUserDocsCreator } from "../../../actions/Creators/NewUserDocs";
import { useLocation } from "react-router-dom";
import Uploader from "./Uploader";

const NewUserUpload = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const notification = useSelector((state) => state.commonReducer.notification);
  const loading = useSelector((state) => state.commonReducer.loader);

  const [files, setFiles] = useState([]);
  const { Title } = Typography;
  const [form] = Form.useForm();

  const acceptedFormats = ["pdf"];
  const setFilteredFiles = (files) => {
    setFiles(
      files.filter((file) =>
        acceptedFormats.includes(file.name.split(".").reverse()[0])
      )
    );
  };

  const onSubmit = async (e) => {
    const { user_id } = location.state || {};
    if (user_id) {
      dispatch(uploadNewUserDocsCreator(files, user_id));
    }
  };

  return (
    <SidePanel>
      <div className="login-form-wrapper">
        <Row align="middle" justify="center">
          <Col xxl={24} className="request-header">
            <Link to="/login" className="btn-request-back">
              <ArrowLeftOutlined />
            </Link>
            <Title level={2}>Upload documents</Title>
          </Col>
        </Row>

        <Form
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          className="cc-form-group"
          validateTrigger="onBlur"
        >
          <Row className="content-header">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Uploader
                files={files}
                setFiles={(files) => setFilteredFiles(files)}
                type="new_user"
                accept={".pdf"}
                vendor={"asd"}
              />
            </Col>
          </Row>
          {files.length > 0 ? (
            <Row className="content-header">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Table
                  dataSource={files.map((file, index) => ({
                    key: index,
                    id: index + 1,
                    name: file.name,
                  }))}
                  pagination={false}
                  rowClassName={() => "red-row"}
                >
                  <Table.Column title="ID" dataIndex="id" key="id" />
                  <Table.Column title="Name" dataIndex="name" key="name" />
                  <Table.Column
                    title="Action"
                    key="action"
                    render={(_, record) => (
                      <Button
                        type="link"
                        icon={<DeleteOutlined style={{ color: "red" }} />}
                        onClick={() => {
                          setFiles(files.filter((_, i) => i !== record.key));
                        }}
                      />
                    )}
                  />
                </Table>
              </Col>
            </Row>
          ) : (
            <NoFilesChoosen />
          )}

          <Form.Item>
            <Button
              className="form_button btn-login"
              block
              htmlType="submit"
              style={{ marginTop: "10px" }}
              loading={loading}
              disabled={files.length === 0}
            >
              Submit
            </Button>
          </Form.Item>
          {notification.open && notification.message ? (
            <Notification
              msg={notification.message}
              type={notification.type}
              clearMsg={() => {
                dispatch(actionDispatch(HIDE_NOTIFICATION));
              }}
            />
          ) : null}
        </Form>
      </div>
    </SidePanel>
  );
};
export default NewUserUpload;
