import React, { useState, useEffect } from "react";
import moment from "moment";
import { Row, Col, Table, Badge, Button, DatePicker, Input, Select, Tooltip, Progress } from "antd";
import { Link, useLocation } from "react-router-dom";
import { CommentOutlined, DeleteOutlined, HistoryOutlined } from "@ant-design/icons";
import Notification from "../../Common/Notification";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Common/Loader";
import { HIDE_NOTIFICATION } from "../../actions/Types";
import { getPoComplianceAPI } from "../../actions/Creators/PO_Compliance.js";
import { VIEW_PO_ROUTE } from "../../Constants/routeNames";
import { Status, badgeColor } from "../PO/POStatus";
import Modal from "../../Common/Modal";
import Authorized from "../../Role-Authorization/Authorized";
import { ROLES_DISCREPANCY_APPROVER_VIEW, ROLES_PO_ADD_VIEW } from "../../utils/constants";
import CommentsData from "../../Common/CommentsCard";
import { compliance_data } from "./data.js";

import "./po-compliance.scss";

const Index = () => {
  const { Option } = Select;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  const sort = queryParams.get("sort");
  const search = queryParams.get("search");

  const [complianceData, setComplianceData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [params, setParams] = useState({
    search: "",
  });

  const onClearFilters = () => {
    setParams({
      search: "",
    });
  };

  const onChangeHandler = (e) => {
    setParams({
      ...params,
      [e.target.name]: e.target.value,
    });
  };

  const columns = [
    {
      title: "#",
      dataIndex: "serialNumber",
      align: "center",
      render: (text, object, index) => index + 1,
    },
    {
      title: "Site Name",
      dataIndex: "customer_location",
      ellipsis: true,
      render: (data, object) => {
        return (
          <Link className="clr-link" to={`${VIEW_PO_ROUTE}?location=${object?.customer_location}`}>
            {data}
          </Link>
        );
      },
    },
    {
      title: "Site Location",
      // dataIndex: "site_name",
      ellipsis: true,
      render: (data) => {
        return (
          <Link className="clr-link" to={`${VIEW_PO_ROUTE}?location=${data?.customer_location}`}>
            {data?.city}, {data?.state}
          </Link>
        );
      },
    },

    {
      title: "POs Processed",
      // dataIndex: "pos_processed",
      dataIndex: "po_number_2023",
      // render: (data) => (),
      ellipsis: true,
    },
    {
      title: "POs with Discrepancy",
      // dataIndex: "pos_non_compliant",
      dataIndex: "powithdiscrepancy",
      ellipsis: true,
      // render: (contract_associate) => (contract_associate ? contract_associate : "N/A"),
    },
    {
      title: "Total Amount PO",
      dataIndex: "total_amount_po",
      dataIndex: "totalamountwithoutdiscrepancy",
      render: (amount, data) => {
        let totalAmount = amount ? amount : data?.totalamount;

        return totalAmount || totalAmount == 0
          ? `$${totalAmount
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
          : "$0";
      },
      className: "bold-im",
    },

    {
      title: "PO with Discrepancy Amount",
      // dataIndex: "total_amount_non_compliant",
      dataIndex: "totalamountwithdiscrepancy",
      render: (amount) =>
        amount || amount == 0
          ? `$${amount
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
          : "$0",
      className: "bold-im",
    },
    {
      title: "Rebatable Spend",
      // dataIndex: "total_amount_non_compliant",
      dataIndex: "total_rebatable_amount",
      render: (amount) =>
        amount || amount == 0
          ? `$${amount
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
          : "$0",
      className: "bold-im",
    },
    {
      title: "Non-Rebatable Spend",
      // dataIndex: "total_amount_non_compliant",
      dataIndex: "total_non_rebatable_amount",
      render: (amount) =>
        amount || amount == 0
          ? `$${amount
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
          : "$0",
      className: "bold-im",
    },

    {
      title: "Growth %",
      // dataIndex: "status",
      render: (data) => {
        let backgroundColor;
        const percentage = data.growth;

        if (percentage >= 80) {
          backgroundColor = "#08979C";
        } else if (percentage >= 50) {
          backgroundColor = "#F6BD16";
        } else if (percentage < 0) {
          backgroundColor = "#FF0000";
        } else {
          backgroundColor = "#FB7D59";
        }

        return <Progress percent={percentage < 0 ? Math.abs(percentage) : percentage} strokeColor={backgroundColor} showInfo={true} format={() => `${Math.round(percentage)}%`} />;
      },
    },
    {
      title: "Actions",
      dataIndex: "view",
      align: "center",
      align: "left",
      render: (text, row) => {
        return (
          <Row align="middle" justify="left">
            <Col span={8}>
              <Tooltip title={`View Purchase Orders on ${row?.city}, ${row?.state}`}>
                <Link className="clr-link" to={`${VIEW_PO_ROUTE}?location=${row?.customer_location}`}>
                  <span className="icon-row-view"></span>
                </Link>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];

  useEffect(() => {
    const getPOCompliance = async () => {
      setComplianceData({ loading: true });
      const data = await getPoComplianceAPI(search || params?.search);
      if (data.code === 200) {
        if (type && sort) {
          // Will refactor this code via key/value. For now don't have much time :/
          if (type === "rebatable_spend") {
            if (sort === "desc") {
              // Sorting the data by 'total_rebatable_amount' in descending order
              setComplianceData({ loading: false, success: data.data.sort((a, b) => b.total_rebatable_amount - a.total_rebatable_amount) });
            } else {
            }
          } else if (type === "non_rebatable_spend") {
            if (sort === "desc") {
              // Sorting the data by 'total_rebatable_amount' in descending order
              setComplianceData({ loading: false, success: data.data.sort((a, b) => b.total_non_rebatable_amount - a.total_non_rebatable_amount) });
            } else {
            }
          } else if (type === "growth") {
            setComplianceData({
              loading: false,
              success: data.data.filter((obj) => obj.growth > 0),
            });
          } else if (type === "non_growth") {
            setComplianceData({
              loading: false,
              success: data.data.filter((obj) => obj.growth < 0),
            });
          } else if (type === "Amount") {
            setComplianceData({
              loading: false,
              success: data.data.sort((a, b) => b.totalamountwithdiscrepancy - a.totalamountwithdiscrepancy),
            });
          } else if (type === "Count") {
            setComplianceData({
              loading: false,
              success: data.data.sort((a, b) => Number(b.po_number_2023) - Number(a.po_number_2023)),
            });
          }
        } else {
          setComplianceData({ loading: false, success: data.data });
        }
      } else {
        setComplianceData({ loading: false, error: data.data.error });
      }
    };

    getPOCompliance();
  }, [params, type, sort, search]);

  return (
    <>
      <Row className="content-header">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <h2>Site Compliance</h2>
        </Col>
      </Row>
      <Row className="filter-wrapper" gutter={[24, 12]} align="top">
        <Col xs={12} sm={12} md={6} lg={10} xl={10} xxl={5}>
          <Input placeholder="Search..." name="search" onChange={onChangeHandler} value={params?.search} prefix={<span class="icon-search"></span>} />
        </Col>
      </Row>

      <Table
        columns={columns}
        loading={false}
        scroll={{ x: 1000 }}
        dataSource={complianceData?.success ? complianceData?.success : []}
        pagination={{
          defaultPageSize: 10,
          position: ["none", "bottomCenter"],
          total: complianceData?.success ? complianceData?.success?.length : 0,
          pageSizeOptions: ["10", "15", "20", "50"],
          locale: { items_per_page: "" },
          showTotal: () => `Showing 10 records.`,
          showSizeChanger: true,
          current: currentPage,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
          },
        }}
      />
    </>
  );
};

export default Index;
