import myImage from "../../assets/images/dashboard-img.png"; 

const SampleDashboard = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <img
        src={myImage}
        alt="Descriptive Alt Text"
        style={{ maxWidth: "100%", height: "auto" }}
      />
    </div>
  );
};

export default SampleDashboard;
