import React, { useEffect, useState } from "react";
import moment from "moment";
import _ from "lodash";
import { Row, Col, Card, Typography, Switch, Divider } from "antd";

import SimpleCard from "../../Common/Card/SimpleCard";

import { useDispatch, useSelector } from "react-redux";
import PieChart from "../../Common/Charts/Pie";
import PieChartOld from "../../Common/Charts/PieOld";
import ColumnChart from "../../Common/Charts/Column";
import ColumnChartOld from "../../Common/Charts/ColumnOld";
import LineChart from "../../Common/Charts/Line";
import BarChart from "../../Common/Charts/Bar";
import RadialChart from "../../Common/Charts/Radial";
import TreeMap from "../../Common/Charts/Treemap";
import Bubble from "../../Common/Charts/Bubble";
import Authorized from "../../Role-Authorization/Authorized";
import { ROLES_CONTRACTS_INVOICE_MANANGER, ROLES_TRUPACTA_TEAM_VIEW, ROLES_PO_ADD_VIEW, ROLES_DISCREPANCY_APPROVER_VIEW, ROLES_CONTRACT_UPLOAD_MANAGER, ROLES_INVOICE_MANAGER, ROLES_REPORT_VIEW } from "../../utils/constants";
import { badgeColor } from "../Contracts/ContractStatus";
import ReportDashboard from "./ReportDashboard";
import SimpleCardDashboard from "../../Common/Card/SimpleCardDashboard";
import {
  getContractRenewals,
  getTotalCounts,
  getDisrepencies,
  getProcessTrends,
  getTopSKUs,
  getTopVendors,
  getVendorScores,
  getContractsCount,
  getPendingApprovalPOAndInvoiceCounts,
  getWeeklyPOAndInvoice,
  getRecentRecordEntries,
  getWeeklyContractPOsInvoicesRecords,
  getCurrentMonthPOsInvoicesContracts,
  getSitesWithCostDiscrepanciesAPI,
} from "../../actions/Creators/Dashboard";
import { badgeColor as POBadgeColor } from "../PO/POStatus";
import { convertToInternationalCurrencySystem } from "./HelperFunctions";
import HeatMap from "../../Common/Charts/Heatmap";
import RecentsTable from "../../Common/Tables/RecentsTable";
import { ContractsColumn, InvoicesColumns, POColumns } from "./DashboardTableCol";
import VendorScore from "./VendorScore";
import { checkSession } from "../../Services/Auth/auth";
import Information from "../../assets/images/information-icon.svg";

import "./Dashboard.scss";

import Meter from "../../Common/Charts/Meter";

const Dashboard = () => {
  const dispatch = useDispatch();

  const dashboard = useSelector((state) => state.dashboard);

  const organization = useSelector((state) => state.header.selectedOrganization);

  const vendor = useSelector((state) => state.header.selectedVendor);

  const rolesArr = useSelector((state) => state.auth.roles);
  const myRoles = rolesArr.length ? rolesArr.map((roles) => roles.role.name) : [];

  const [topSitesCostDesc, setTopSitesCostDesc] = useState(null);

  const checkActiveSession = async () => {
    const hasActiveSession = await checkSession();

    if (hasActiveSession && hasActiveSession.code === 200) {
      const v = vendor | 0;

      if (myRoles.some((r) => ["discrepancy-approver"].includes(r))) {
        dispatch(getContractsCount(organization.id));
        dispatch(getPendingApprovalPOAndInvoiceCounts(organization.id));
        dispatch(getWeeklyPOAndInvoice(organization.id));
        dispatch(getRecentRecordEntries(organization.id));
        dispatch(getWeeklyContractPOsInvoicesRecords(organization.id));
        dispatch(getCurrentMonthPOsInvoicesContracts(organization.id));
      } else {
        dispatch(getContractRenewals(v));
        dispatch(getTotalCounts(v));
        dispatch(getDisrepencies(v));
        dispatch(getProcessTrends(v));
        dispatch(getTopSKUs(v));
        dispatch(getTopVendors(v));
      }
    }
  };

  useEffect(() => {
    const getTopSitesCostDesc = async () => {
      setTopSitesCostDesc({ loading: true });

      const data = await getSitesWithCostDiscrepanciesAPI(vendor);
      if (data.code === 200) {
        setTopSitesCostDesc({ loading: false, success: data.data });
      } else {
        setTopSitesCostDesc({ loading: false, error: data.data.error });
      }
    };

    getTopSitesCostDesc();
  }, [vendor]);

  useEffect(() => {
    checkActiveSession();
  }, [organization, vendor]);

  useEffect(() => {
    dispatch(getVendorScores("all"));
  }, []);

  const contractRenewal = dashboard.contractRenewal ? dashboard.contractRenewal.contracts : [];
  const processingTrend = dashboard.processingTrend ? dashboard.processingTrend : [];
  const discrepency = dashboard.discrepency;
  const topSku = dashboard.topSku ? dashboard.topSku : [];
  const topVendor = dashboard.topVendor ? dashboard.topVendor : [];
  const totalCount = dashboard.totalCount ? dashboard.totalCount : {};
  const vendorScores = dashboard.vendorScore ? dashboard.vendorScore : [];

  const [data, setData] = useState([]);
  const { Title } = Typography;

  useEffect(() => {
    if (vendorScores && vendorScores.length > 0) {
      setData(vendorScores.sort((v1, v2) => (v1.vendor_id == vendor ? -1 : v2.vendor_id == vendor ? 1 : v2.total - v1.total)));
    }
  }, [vendorScores, vendor]);

  // For Billing Manager
  const formatDataForPO = (data, formatBy) => {
    const weekValuesWithDays = data.map((data) => {
      const { createdAt } = data;
      const day = moment(createdAt).format("dddd");
      return { ...data, day };
    });

    const groupedData = _.chain(weekValuesWithDays)
      .groupBy(`${formatBy}`)
      .map((value, key) => ({ day: key, data: value }))
      .value();

    let resultDates = [];
    let current = moment();
    let n = 7;
    while (n > 0) {
      resultDates.push({ day: current.format("dddd"), data: [] });
      current.subtract(1, "day");
      n--;
    }
    resultDates.reverse();

    //Show all days irrespective of zero records
    resultDates.forEach((r) => {
      groupedData.forEach((group) => {
        if (r.day === group.day) {
          r.day = group.day;
          r.data = group.data;
        }
      });
    });
    let finalData = [];
    resultDates.forEach((group) => {
      const pending = group && group.data && group.data.filter((g) => g.status === "discrepancy-approval");
      const confirmed = group && group.data && group.data.filter((g) => g.status === "approved w/ discrepancy");
      const rejected = group && group.data && group.data.filter((g) => g.status === "rejected");
      finalData = [
        ...finalData,
        {
          day: group.day,
          name: "Pending Approval",
          number: pending.length,
        },
        {
          day: group.day,
          name: "Approved w/ discrepancy",
          number: confirmed.length,
        },
        { day: group.day, name: "Rejected", number: rejected.length },
      ];
    });
    return finalData;
  };
  const formatDataForInvoice = (data, formatBy) => {
    const weekValuesWithDays = data.map((data) => {
      const { createdAt } = data;
      const day = moment(createdAt).format("dddd");
      return { ...data, day };
    });

    const groupedData = _.chain(weekValuesWithDays)
      .groupBy(`${formatBy}`)
      .map((value, key) => ({ day: key, data: value }))
      .value();

    let resultDates = [];
    let current = moment();
    let n = 7;
    while (n > 0) {
      resultDates.push({ day: current.format("dddd"), data: [] });
      current.subtract(1, "day");
      n--;
    }
    resultDates.reverse();

    //Show all days irrespective of zero records
    resultDates.forEach((r) => {
      groupedData.forEach((group) => {
        if (r.day === group.day) {
          r.day = group.day;
          r.data = group.data;
        }
      });
    });
    let finalData = [];
    resultDates.forEach((group) => {
      const pending = group && group.data && group.data.filter((g) => g.status === "discrepancy-approval");
      const confirmed = group && group.data && group.data.filter((g) => g.status === "approved w/ discrepancy");
      const rejected = group && group.data && group.data.filter((g) => g.status === "rejected");
      finalData = [
        ...finalData,
        {
          day: group.day,
          name: "Pending Approval",
          number: pending.length,
        },
        {
          day: group.day,
          name: "Approved w/ discrepancy",
          number: confirmed.length,
        },
        { day: group.day, name: "Rejected", number: rejected.length },
      ];
    });
    return finalData;
  };

  let POWeeklyDataWithInvoice = dashboard.weeklyPOAndInvoice && dashboard.weeklyPOAndInvoice.pos && formatDataForPO(dashboard.weeklyPOAndInvoice.pos, "day");
  let invoiceWeeklyDataWithPO = dashboard.weeklyPOAndInvoice && dashboard.weeklyPOAndInvoice.invoices && formatDataForInvoice(dashboard.weeklyPOAndInvoice.invoices, "day");
  let pieDataChartForLastWeekEntityRecords = [
    {
      type: "Contract",
      value: dashboard.weeklyContractPOInvoices && dashboard.weeklyContractPOInvoices.contracts,
    },
    {
      type: "PO",
      value: dashboard.weeklyContractPOInvoices && dashboard.weeklyContractPOInvoices.pos,
    },
    {
      type: "Invoice",
      value: dashboard.weeklyContractPOInvoices && dashboard.weeklyContractPOInvoices.invoices,
    },
  ];
  let pieDataChartForMonthEntityRecords = [
    {
      type: "Contract",
      value: dashboard.monthlyContractsPOsInvoices && dashboard.monthlyContractsPOsInvoices.contracts,
    },
    {
      type: "PO",
      value: dashboard.monthlyContractsPOsInvoices && dashboard.monthlyContractsPOsInvoices.pos,
    },
    {
      type: "Invoice",
      value: dashboard.monthlyContractsPOsInvoices && dashboard.monthlyContractsPOsInvoices.invoices,
    },
  ];

  return (
    // <Spin size="large" spinner={loader}>
    <>
      <div className="dashboard-wrapper">
        <div className="col-left">
          <Row gutter={[24, 24]} className="db-row no-mb" justify="center">
            <Col xl={4}>
              <Card className="dashboard-number-box dashboard-card-t1 combine-data">
                <Row>
                  <Col xs={24} sm={24}>
                    <SimpleCardDashboard unit={totalCount.contracts} title="Contracts" boxClass="round-clr-t1" />
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col xl={4}>
              <Card className="dashboard-number-box dashboard-card-t1 combine-data">
                <Row>
                  <Col xs={24} sm={24}>
                    <SimpleCardDashboard unit={totalCount.purchase_orders} title="Purchase Order" boxClass="round-clr-t2" />
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col xl={4}>
              <Card className="dashboard-number-box dashboard-card-t1 combine-data">
                <Row>
                  <Col xs={24} sm={24}>
                    <SimpleCardDashboard unit={totalCount.invoices} title="Invoices" boxClass="round-clr-t3" />
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col xl={4}>
              <Card className="dashboard-number-box dashboard-card-t1 combine-data">
                <Row>
                  <Col xs={24} sm={24}>
                    <SimpleCardDashboard unitSymbol="$" unit={convertToInternationalCurrencySystem(totalCount.committed)} title="Committed Spend" boxClass="round-clr-t4" />
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col xl={4}>
              <Card className="dashboard-number-box dashboard-card-t1 combine-data">
                <Row>
                  <Col xs={24} sm={24}>
                    <SimpleCardDashboard unitSymbol="$" unit={convertToInternationalCurrencySystem(totalCount.spent)} title="Actual Spend" boxClass="round-clr-t5" />
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col xl={4}>
              <Card className="dashboard-number-box dashboard-card-t1 combine-data">
                <Row>
                  <Col xs={24} sm={24}>
                    <SimpleCardDashboard unitSymbol="$" unit={convertToInternationalCurrencySystem(totalCount.savings + totalCount.invoiceAmount)} title="Discrepancy Savings" boxClass="round-clr-t6" />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <div className="dashboard-wrapper">
        <div className="col-left no-padding-top">
          <Authorized roleList={ROLES_REPORT_VIEW}>
            <ReportDashboard />
          </Authorized>

          <Authorized roleList={ROLES_DISCREPANCY_APPROVER_VIEW}>
            <Row gutter={[24, 24]} className="db-row" justify="center">
              <Col xs={8} sm={8} md={8} lg={12} xl={7}>
                <SimpleCard unit={dashboard.pendingApprovalPOAndInvoices.pendingApprovalPOs} title="Pending POs" svg={<span className="box-ico icon-purchase-order"></span>} boxClass="clr-t3" />
              </Col>
              <Col xs={8} sm={8} md={8} lg={12} xl={7}>
                <SimpleCard unit={dashboard.pendingApprovalPOAndInvoices.pendingApprovalInvoices} title="Pending Invoices" svg={<span className="box-ico icon-file-invoice-dollar"></span>} boxClass="clr-t2" />
              </Col>
              <Col xs={8} sm={8} md={8} lg={24} xl={10}>
                <SimpleCard unit={dashboard.contractsCount.total} title="Total Contracts" svg={<span className="box-ico icon-icon-contract"></span>} boxClass="clr-t1" />
              </Col>
            </Row>

            <Row gutter={[24, 24]} className="db-row" justify="center">
              <Col xs={12} sm={12} md={14} lg={14} xl={14} xxl={16}>
                <ColumnChartOld
                  labelText="Files Summary"
                  dataSet={[
                    {
                      type: "Week",
                      xField: "day",
                      data: POWeeklyDataWithInvoice,
                      legend: [
                        {
                          type: "Pending Approval",
                          color: POBadgeColor["discrepancy-approval"],
                        },
                        {
                          type: "Approved W/ Discrepancy",
                          color: POBadgeColor["approved w/ discrepancy"],
                        },
                        { type: "Rejected", color: POBadgeColor.rejected },
                      ],
                    },
                    {
                      type: "Week",
                      xField: "day",
                      data: invoiceWeeklyDataWithPO,
                      legend: [
                        {
                          type: "Pending Approval",
                          color: POBadgeColor["discrepancy-approval"],
                        },
                        {
                          type: "Approved W/ Discrepancy",
                          color: POBadgeColor["approved w/ discrepancy"],
                        },
                        { type: "Rejected", color: POBadgeColor.rejected },
                      ],
                    },
                  ]}
                  types={["Purchase Order", "Invoices"]}
                />
              </Col>

              <Col xs={12} sm={12} md={10} lg={10} xl={10} xxl={8}>
                <PieChartOld
                  labelText="Composition"
                  uploadInfo={[
                    {
                      type: "Week",
                      total: dashboard.weeklyContractPOInvoices && dashboard.weeklyContractPOInvoices.contracts + dashboard.weeklyContractPOInvoices.pos + dashboard.weeklyContractPOInvoices.invoices,
                      countInfo: pieDataChartForLastWeekEntityRecords,
                      data: [
                        {
                          status: "Contract",
                          color: "#13c2c2",
                          percent:
                            dashboard.weeklyContractPOInvoices &&
                            dashboard.weeklyContractPOInvoices.contracts &&
                            Math.round((dashboard.weeklyContractPOInvoices.contracts / (dashboard.weeklyContractPOInvoices.contracts + dashboard.weeklyContractPOInvoices.pos + dashboard.weeklyContractPOInvoices.invoices)) * 100),
                        },
                        {
                          status: "PO",
                          color: "#08979c",
                          percent:
                            dashboard.weeklyContractPOInvoices &&
                            Math.round((dashboard.weeklyContractPOInvoices.pos / (dashboard.weeklyContractPOInvoices.contracts + dashboard.weeklyContractPOInvoices.pos + dashboard.weeklyContractPOInvoices.invoices)) * 100),
                        },
                        {
                          status: "Invoice",
                          color: "#fb7d59",
                          percent:
                            dashboard.weeklyContractPOInvoices &&
                            Math.round((dashboard.weeklyContractPOInvoices.invoices / (dashboard.weeklyContractPOInvoices.contracts + dashboard.weeklyContractPOInvoices.pos + dashboard.weeklyContractPOInvoices.invoices)) * 100),
                        },
                      ],
                    },
                    {
                      type: "Month",
                      total: dashboard.monthlyContractsPOsInvoices && dashboard.monthlyContractsPOsInvoices.contracts + dashboard.monthlyContractsPOsInvoices.pos + dashboard.monthlyContractsPOsInvoices.invoices,
                      countInfo: pieDataChartForMonthEntityRecords,
                      data: [
                        {
                          status: "Contract",
                          color: "#13c2c2",
                          percent:
                            dashboard.monthlyContractsPOsInvoices &&
                            dashboard.monthlyContractsPOsInvoices.contracts &&
                            Math.round((dashboard.monthlyContractsPOsInvoices.contracts / (dashboard.monthlyContractsPOsInvoices.contracts + dashboard.monthlyContractsPOsInvoices.pos + dashboard.monthlyContractsPOsInvoices.invoices)) * 100),
                        },
                        {
                          status: "PO",
                          color: "#08979c",
                          percent:
                            dashboard.monthlyContractsPOsInvoices &&
                            Math.round((dashboard.monthlyContractsPOsInvoices.pos / (dashboard.monthlyContractsPOsInvoices.contracts + dashboard.monthlyContractsPOsInvoices.pos + dashboard.monthlyContractsPOsInvoices.invoices)) * 100),
                        },
                        {
                          status: "Invoice",
                          color: "#fb7d59",
                          percent:
                            dashboard.monthlyContractsPOsInvoices &&
                            Math.round((dashboard.monthlyContractsPOsInvoices.invoices / (dashboard.monthlyContractsPOsInvoices.contracts + dashboard.monthlyContractsPOsInvoices.pos + dashboard.monthlyContractsPOsInvoices.invoices)) * 100),
                        },
                      ],
                    },
                  ]}
                />
              </Col>
            </Row>

            <Row gutter={[24, 24]} className="db-row" justify="center">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <RecentsTable
                  initialData={dashboard.recentContractAndInvoiceAndPO}
                  title="Recent Uploads"
                  tabs={[
                    {
                      type: "Contracts",
                      data: dashboard.recentContractAndInvoiceAndPO && dashboard.recentContractAndInvoiceAndPO.contracts,
                      columns: ContractsColumn(),
                    },
                    {
                      type: "Purchase Orders",
                      data: dashboard.recentContractAndInvoiceAndPO && dashboard.recentContractAndInvoiceAndPO.pos,
                      columns: POColumns(),
                    },
                    {
                      type: "Invoices",
                      data: dashboard.recentContractAndInvoiceAndPO && dashboard.recentContractAndInvoiceAndPO.invoices,
                      columns: InvoicesColumns(),
                    },
                  ]}
                />
              </Col>
            </Row>
          </Authorized>

          <Authorized roleList={[...ROLES_TRUPACTA_TEAM_VIEW, ...ROLES_PO_ADD_VIEW]}>
            <Row gutter={[24, 24]} className="db-row">
              <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
                <ColumnChart
                  labelText="Contracts Renewal"
                  breakHeader={false}
                  dataSet={[
                    {
                      xField: "month",
                      yField: "count",
                      data: contractRenewal,
                      color: ({ month }) => {
                        if (month === "Overdue") {
                          return "#F4664A";
                        }

                        return "#13c2c2";
                      },
                    },
                  ]}
                  types={["6 months", "12 Months"]}
                />
              </Col>
              <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
                <PieChart labelText="Cost Discrepancies Identified" colors={["#F4664A", "#13c2c2"]} uploadInfo={discrepency} />
              </Col>

              <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
                <Card className="dashboad-chart-box dashboard-card-t1 ">
                  <Row className="mb-1">
                    <Row gutter={[0, 24]} className="db-row w-full" justify="start">
                      <Col xs={24} sm={16} md={16} lg={16} xl={16}>
                        <Title level={3}>
                          Vendor Score Index <img src={Information} alt="information-icon" />
                        </Title>
                      </Col>
                      <Col xs={24} sm={8} md={18} lg={8} xl={8} className="align-right">
                        <Switch
                          defaultChecked
                          checkedChildren="Top 3"
                          unCheckedChildren="Bottom 3"
                          onChange={(val) => {
                            if (val) {
                              setData([...vendorScores.sort((v1, v2) => (v1.vendor_id == vendor ? -1 : v2.vendor_id == vendor ? 1 : v2.total - v1.total))]);
                            } else {
                              setData([...vendorScores.sort((v1, v2) => (v1.vendor_id == vendor ? -1 : v2.vendor_id == vendor ? 1 : v1.total - v2.total))]);
                            }
                          }}
                        />
                      </Col>
                    </Row>

                    <Row gutter={[0, 0]} className="customscrollHeight" justify="start">
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        {data.map((s, i) => {
                          return (
                            i < 5 && (
                              <>
                                <Meter title={s.vendor_name} score={s} />
                                <Divider style={{ margin: "3px 0px" }} />
                              </>
                            )
                          );
                        })}
                      </Col>
                    </Row>
                  </Row>
                </Card>
              </Col>

              <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
                <ColumnChart
                  labelText="Top 5 Sites with Cost Discrepancies"
                  dataSet={[
                    {
                      xField: "citywithstate",
                      data: topSitesCostDesc?.success ?? [],
                      color: () => {
                        // if (month === "Overdue") {
                        //   return "#F4664A";
                        // }

                        return "#13c2c2";
                      },
                    },
                  ]}
                  types={["Amount", "Count"]}
                  breakHeader={true}
                />
              </Col>

              <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
                {/*<BarChart labelText="Top 5 SKU Spend" chartData={topSku} />*/}
                <TreeMap labelText="Top 25 SKU by Spend" chartData={topSku} />
              </Col>

              <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
                <Card className="dashboad-chart-box dashboard-card-t1 ">
                  <Row className="mb-1">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <RadialChart labelText="Top 10 Suppliers by Annual Spend" chartData={topVendor}></RadialChart>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                <LineChart labelText="No. of Documents Processed" chartData={processingTrend} />
              </Col>
            </Row>
          </Authorized>
        </div>
        {/* col-left */}

        {/* <Authorized roleList={[...ROLES_TRUPACTA_TEAM_VIEW, ...ROLES_PO_ADD_VIEW]}>
          <div className="col-right dashboard-side-chart">
  
  
           <VendorScore scores={vendorScores} vendorId={vendor} /> 
  
         

            <Row gutter={[0, 24]} className="dt-row" justify="start">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <RadialChart labelText="Annual Spend by Suppliers" chartData={topVendor}></RadialChart>
              </Col>
            </Row>
          </div>
        </Authorized> */}
      </div>
    </>

    // </Spin>
  );
};

export default Dashboard;
