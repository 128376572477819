import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { pdfjs } from "react-pdf";
import { Col, Row, Form, Button, Spin, Input, DatePicker, Switch, AutoComplete, Select } from "antd";
import _ from "lodash";
import moment from "moment";
import NumberFormat from "react-number-format";
import FieldGenerator from "../../Common/FieldGenerators/FieldGenerator";
import { fetchTemplatesById } from "../../actions/Creators/Templates";
import history from "../../History/history";
import { createTemplatedContract, fetchContractsById } from "../../actions/Creators/Contracts";
import { HIDE_NOTIFICATION } from "../../actions/Types";
import Notification from "../../Common/Notification";
import { actionDispatch, clearSelectedContractUrlFile, getVendors, getSelectedContractTemplate, triggerNotification } from "../../actions/Creators";
import AllPagesPDFReader from "../../utils/AllPagePdfReader";
import { getFromLocal } from "../../utils/cache";
import { openPDF } from "../../utils/s3Upload";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default withRouter(({ match }) => {
  const { id, contractId } = match.params;
  const [tableForm] = Form.useForm();
  const [parentForm] = Form.useForm();
  const template = useSelector((state) => state.templatesReducer.selectedTemplate);
  const loader = useSelector((state) => state.commonReducer.loader);
  const contract = useSelector((state) => state.contract.contract);
  const notification = useSelector((state) => state.commonReducer.notification);
  const fileUrl = useSelector((state) => state.contract.contractFileUrl);
  const vendor = useSelector((state) => state.vendor);
  const [selectedVendor, setSelectedVendor] = useState({});
  const [autoRenewal, setAutoRenewal] = useState(false);
  const [isCollapseTrue, setIsCollapseTrue] = useState(false);
  const [rebates, setRebates] = useState(false);
  const [termination, setTermination] = useState(false);
  const [sla, setSla] = useState(false);
  const [tiered, setTiered] = useState(false);
  const [paymentTerms, setPaymentTerms] = useState("0");

  const calculateNetPrice = () => {
    const updatedValues = tableForm.getFieldsValue();
    let afterDiscount = 0;
    let newobj = {};
    let maxRows = 0;
    let columnsList = Object.keys(updatedValues).map((dt) => {
      maxRows = dt.split("-")[1];
      return `${dt.split("-")[0]}`;
    });
    columnsList = [...new Set(columnsList)];
    let dto = [...Array(parseInt(maxRows) + 1).keys()].map((_, index) => {
      return columnsList.reduce((result, value) => {
        result[value] = updatedValues[`${value}-${index}`];
        return result;
      }, {});
    });
    dto.forEach((i, index) => {
      i.unit_price = i.unit_price && i.unit_price;
      i.discount = i.discount && i.discount;
      if (i.discount <= 100 && !isNaN(i.discount) && !isNaN(i.unit_price)) {
        afterDiscount = (i.discount / 100) * i.unit_price;
        i.final_price = i.unit_price - afterDiscount;
      } else {
        i.final_price = i.unit_price;
      }

      Object.keys(i).forEach((key) => {
        if (key === "final_price") newobj[`final_price-${index}`] = Number(i.final_price.toFixed(2));
      });

      tableForm.setFieldsValue({
        ...tableForm,
        ...newobj,
      });
    });
  };

  const removeFormatting = (numString) => {
    let numInt = numString.toString().replace(/[$,%\s]/g, "");
    return Number(numInt);
  };

  const dispatch = useDispatch();
  const onSubmit = async (obj) => {
    tableForm.submit();
    let contractColumns = [
      "vendor",
      "title",
      "auto_renewal",
      "contract_number",
      "createdAt",
      "expiry",
      "initial_term",
      "extension_term",
      "min_order_quantity",
      "min_annual_quantity",
      "min_order_dollar_commitment",
      "min_annual_dollar_commitment",
      "yearly_price_increment_percentage",
      "rebates",
      "payment_terms",
      "termination",
      "sla",
      "tiered",
    ];
    let objectToSend = {
      values: {},
    };
    let contractToUpdate = {};
    const tableValues = tableForm.getFieldsValue();
    let maxRows = 0;
    let columnsList = Object.keys(tableValues).map((dt) => {
      maxRows = dt.split("-")[1];
      return `${dt.split("-")[0]}`;
    });
    columnsList = [...new Set(columnsList)];
    const dto = [...Array(parseInt(maxRows) + 1).keys()].map((_, index) => {
      return columnsList.reduce((result, value, colIndex) => {
        result[value] = tableValues[`${value}-${index}`];
        return result;
      }, {});
    });

    if (obj.issue_date.isSameOrAfter(obj.expiry.format("YYYY-MM-DD")) || obj.effective_date.isBefore(obj.issue_date.format("YYYY-MM-DD")) || obj.effective_date.isSameOrAfter(obj.expiry.format("YYYY-MM-DD"))) {
      // Check if expiry date is after issue date
      if (obj.issue_date.isSameOrAfter(obj.expiry.format("YYYY-MM-DD"))) {
        dispatch(triggerNotification("error", "Expiry date should be greater than Issue date."));
        dispatch(actionDispatch(HIDE_NOTIFICATION));
      }

      // Check if effective date is after issue date
      if (obj.effective_date.isBefore(obj.issue_date.format("YYYY-MM-DD"))) {
        dispatch(triggerNotification("error", "Issue date should not be greater than effective date."));
        dispatch(actionDispatch(HIDE_NOTIFICATION));
      }

      //Check if effective date is before expiry date
      if (obj.effective_date.isSameOrAfter(obj.expiry.format("YYYY-MM-DD"))) {
        dispatch(triggerNotification("error", "Expiry date should be greater than Effective date."));
        dispatch(actionDispatch(HIDE_NOTIFICATION));
      }

      return;
    }
    objectToSend.organization_id = contract.organization_id;
    objectToSend.contract_id = contract.id;
    objectToSend.template = template._id;
    contractToUpdate.issue_date = moment(obj.issue_date).format("YYYY-MM-DD");
    contractToUpdate.expiry = moment(obj.expiry).format("YYYY-MM-DD");
    contractToUpdate.effective_date = obj.effective_date && moment(obj.effective_date).format("YYYY-MM-DD");
    contractToUpdate.title = obj.title;
    contractToUpdate.vendor = !_.isEmpty(selectedVendor) ? selectedVendor : getDefaultVendor();
    contractToUpdate.auto_renewal = obj.auto_renewal;
    contractToUpdate.rebates = obj.rebates;
    contractToUpdate.payment_terms = obj.payment_terms;
    contractToUpdate.termination = obj.termination;
    contractToUpdate.sla = obj.sla;
    contractToUpdate.tiered = obj.tiered;
    contractToUpdate.contract_number = obj.contract_number;
    contractToUpdate.contract_type = obj.contract_type;
    contractToUpdate.contract_id = contract.id;
    contractToUpdate.organization_id = contract.organization_id;
    contractToUpdate.initial_term = obj.initial_term;
    contractToUpdate.extension_term = obj.extension_term;
    contractToUpdate.min_annual_dollar_commitment = obj.min_annual_dollar_commitment ? removeFormatting(obj.min_annual_dollar_commitment) : null;
    contractToUpdate.min_annual_quantity = obj.min_annual_quantity ? removeFormatting(obj.min_annual_quantity) : null;
    contractToUpdate.min_order_dollar_commitment = obj.min_order_dollar_commitment ? removeFormatting(obj.min_order_dollar_commitment) : null;
    contractToUpdate.min_order_quantity = obj.min_order_quantity ? removeFormatting(obj.min_order_quantity) : null;
    contractToUpdate.yearly_price_increment_percentage = obj.yearly_price_increment_percentage ? removeFormatting(obj.yearly_price_increment_percentage) : null;

    objectToSend.values.sku = dto;
    for (let prop in obj) {
      if (!contractColumns.includes(prop)) {
        objectToSend.values[prop] = obj[prop];
      }
    }
    await tableForm.validateFields();
    const DTO = {
      templtedContractInfo: { ...objectToSend },
      contractInfo: { ...contractToUpdate },
    };
    dispatch(createTemplatedContract(DTO));
    parentForm.resetFields();
    tableForm.resetFields();
  };
  useEffect(() => {
    dispatch(clearSelectedContractUrlFile());
    const organization = getFromLocal("SELECTED_ORGANIZATION", true, true);
    if (_.isEmpty(vendor.vendors)) {
      dispatch(getVendors(organization.id));
    }
    dispatch(fetchContractsById(contractId));
    dispatch(fetchTemplatesById(id));
  }, []);

  useEffect(() => {
    contract && contract.document && contract.document.key && dispatch(getSelectedContractTemplate(contract.document.key));
  }, [contract]);

  useEffect(() => {
    if (!_.isEmpty(vendor.vendors) && !_.isEmpty(contract)) {
      parentForm.setFieldsValue({
        ...contract,
        effective_date: contract.effective_date ? moment(contract.effective_date) : null,
        expiry: contract.expiry ? moment(contract.expiry) : null,
        vendor: vendor.vendors.filter((d) => d.id == contract.vendor_id)[0].title,
      });
    }
  }, [vendor, contract]);

  if (!_.isEmpty(contract)) {
    parentForm.setFieldsValue({
      ...contract,
      effective_date: contract.effective_date ? moment(contract.effective_date) : null,
      expiry: contract.expiry ? moment(contract.expiry) : null,
      ...parentForm.getFieldsValue(),
      contract_type: template.type,
    });
  }
  const getDefaultVendor = () => {
    let obj = {};
    let vendorObj = vendor.vendors.filter((d) => d.id == contract.vendor_id)[0];
    obj.id = vendorObj.id;
    obj.value = vendorObj.title;
    return obj;
  };
  const onVendorChangeHandler = (value, opt) => {
    if (!(_.isEmpty(opt.value) && _.isEmpty(opt.id) && opt.id !== 0)) setSelectedVendor(opt);
    else setSelectedVendor({ id: 0, value: value });
  };
  useEffect(() => {
    if (contract) {
      setAutoRenewal(contract.auto_renewal);
      setRebates(contract.rebates);
      setTermination(contract.termination);
      setSla(contract.sla);
      setTiered(contract.tiered);
      setPaymentTerms(contract.payment_terms);
    }
  }, [contract]);

  const onNewTabOpen = async () => {
    await openPDF(fileUrl);
  };
  return (
    <Spin size="large" spinning={loader}>
      <div
        className="pdf-viewer-wrapper"
        style={{
          height: isCollapseTrue ? "100px" : "400px",
        }}
      >
        <AllPagesPDFReader pdf={fileUrl} />

        <div className="pdf-actions-box">
          <p onClick={onNewTabOpen} className="icon-expand" title="Open in new Tab"></p>
          <p onClick={() => setIsCollapseTrue(!isCollapseTrue)} className="icon-arrow-up" title="Collapse"></p>
        </div>
      </div>
      <Form
        layout="vertical"
        form={parentForm}
        onFinish={(e) => onSubmit(e)}
        // style={{ height: "500px", overflowX: "scroll" }}
      >
        <Row className="content-header">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <h2>Add Contract</h2>
          </Col>
        </Row>

        <h4 className="mt-1">Contract Headers</h4>

        <Row className="" gutter={24} align="top">
          {!_.isEmpty(contract) && (
            <>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Form.Item
                  name={`vendor`}
                  label={"Vendor"}
                  rules={[
                    {
                      required: true,
                      message: "Please select vendor",
                    },
                  ]}
                >
                  <AutoComplete
                    style={{
                      width: 200,
                    }}
                    options={
                      vendor &&
                      vendor.vendors.map((option) => ({
                        id: option.id,
                        value: option.title,
                      }))
                    }
                    placeholder="Vendors"
                    onChange={onVendorChangeHandler}
                    filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Form.Item
                  label="Contract Number"
                  name="contract_number"
                  rules={[
                    {
                      required: true,
                      message: "please provide contract number",
                    },
                  ]}
                >
                  <Input maxLength={50} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Form.Item
                  label="Contract Title"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "please provide contract title",
                    },
                  ]}
                >
                  <Input maxLength={50} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Form.Item label="Contract Category" name="contract_type">
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Form.Item
                  label="Contract Issue Date"
                  name="issue_date"
                  rules={[
                    {
                      required: true,
                      message: "Please provide Contract Issue Date",
                    },
                  ]}
                >
                  <DatePicker format={"YYYY-MM-DD"} placeholder="YYYY-MM-DD" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Form.Item
                  label="Contract Effective Date"
                  name="effective_date"
                  rules={[
                    {
                      required: true,
                      message: "Please provide contract effective date",
                    },
                  ]}
                >
                  <DatePicker format={"YYYY-MM-DD"} placeholder="YYYY-MM-DD" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Form.Item
                  label="Contract Renewal/Expiry Date"
                  name="expiry"
                  rules={[
                    {
                      required: true,
                      message: "Please provide contract expiry date",
                    },
                  ]}
                >
                  <DatePicker format={"YYYY-MM-DD"} placeholder="YYYY-MM-DD" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Form.Item label="Auto Renewal" name="auto_renewal">
                  <Switch value={autoRenewal} checked={autoRenewal} onChange={(e) => setAutoRenewal(e)} />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>

        <h4 className="mt-1">Contract Terms</h4>

        <Row className="" gutter={24} align="top">
          {!_.isEmpty(contract) && (
            <>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Form.Item label="Min order quantity" name="min_order_quantity">
                  <NumberFormat className="ant-input-number" thousandSeparator={true} allowNegative={false} decimalScale={0} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Form.Item label="Min Annual Quantity" name="min_annual_quantity">
                  <NumberFormat className="ant-input-number" thousandSeparator={true} allowNegative={false} decimalScale={0} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Form.Item label="Min order dollar commitment" name="min_order_dollar_commitment">
                  <NumberFormat className="ant-input-number" thousandSeparator={true} allowNegative={false} decimalScale={0} prefix={"$"} decimalScale={2} fixedDecimalScale />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Form.Item label="Min Annual Dollar commitment" name="min_annual_dollar_commitment">
                  <NumberFormat className="ant-input-number" thousandSeparator={true} allowNegative={false} decimalScale={0} prefix={"$"} decimalScale={2} fixedDecimalScale />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Form.Item label="Yearly price Increment %" name="yearly_price_increment_percentage">
                  <NumberFormat className="ant-input-number" thousandSeparator={true} allowNegative={false} decimalScale={0} suffix={"%"} maxLength={4} max={100} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Form.Item label="Initial term" name="initial_term">
                  <Input.TextArea rows={3} maxLength={1500} showCount />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Form.Item label="Extension term" name="extension_term">
                  <Input.TextArea rows={3} maxLength={1500} showCount />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Form.Item label="Payment terms" name="payment_terms">
                  <Select value={paymentTerms} onChange={(e) => setPaymentTerms(e)}>
                    <Select.Option key="30" value={0}>
                      Net 30
                    </Select.Option>
                    <Select.Option key="30+" value={1}>
                      Net 30+
                    </Select.Option>
                    <Select.Option key="prepaid" value={2}>
                      Prepaid
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Form.Item label="Rebates Available" name="rebates">
                  <Switch value={rebates} checked={rebates} onChange={(e) => setRebates(e)} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Form.Item label="Termination of Convenience" name="termination">
                  <Switch value={termination} checked={termination} onChange={(e) => setTermination(e)} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Form.Item label="SLA/Leadtime Credits" name="sla">
                  <Switch value={sla} checked={sla} onChange={(e) => setSla(e)} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Form.Item label="Tiered Pricing" name="tiered">
                  <Switch value={tiered} checked={tiered} onChange={(e) => setTiered(e)} />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>

        {!_.isEmpty(template) &&
          template.records.map((d, index) => {
            return (
              <Row justify="start" key={index}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <h4 className="mt-1">{d.label}</h4>

                  <Row justify="start" gutter={[24, 12]}>
                    {d.fields_set &&
                      d.fields_set.map((field, colIndex) => {
                        return (
                          <Col key={colIndex} span={field.col_type === "table" ? 24 : 6}>
                            <FieldGenerator calculateNetPrice={calculateNetPrice} tableForm={tableForm} field_key={field.key} {...field} />
                          </Col>
                        );
                      })}
                  </Row>
                </Col>
              </Row>
            );
          })}

        <Row className="mt-2" align="top">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Button
              onClick={() => {
                history.goBack();
              }}
              className="btn-primary btn-t2 mr-1"
            >
              <span class="btn-icon icon-arrow-square-left"></span> Back
            </Button>
            <Button className="btn-primary" htmlType="submit">
              <span className="btn-icon icon-tick"></span> Save
            </Button>
          </Col>
        </Row>
      </Form>
      {notification.open && notification.message ? <Notification msg={notification.message} type={notification.type} clearMsg={() => {}} /> : null}
    </Spin>
  );
});
