import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Badge, Card, Row, Col } from "antd";
import { Pie } from "@ant-design/charts";
import Loader from "../../Common/Loader";

const PieChartOld = ({ uploadInfo, labelText }) => {
  const [dataInfo, setDataInfo] = useState(uploadInfo[0]);
  const [selectedOpt, setSelectedOpt] = useState(uploadInfo[0].type);
  const [graphValues, setGraphValues] = useState(uploadInfo[0].countInfo);
  useEffect(() => {
    setDataInfo(uploadInfo[0]);
    setGraphValues(uploadInfo[0].countInfo);
    setSelectedOpt(uploadInfo[0].type);
  }, [uploadInfo]);

  var config = {
    data: graphValues,
    angleField: "value",
    colorField: "type",
    innerRadius: 0.7,
    color: dataInfo.data.map((d) => d.color),
    height: 165,
    radius: 200,
    maxColumnWidth: 15,
    legend: false,
    label: {
      labelLine: null,
      autoHide: true,
      style: {
        opacity: 0,
      },
    },
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
        },
        formatter: function formatter() {
          return `Total\n${dataInfo && dataInfo.total ? dataInfo.total : 0}`;
        },
      },
    },
  };
  return (
    <Card className="dashboad-chart-box dashboard-card-t1">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3>{labelText}</h3>

        {uploadInfo && uploadInfo[0].type && (
          <div className="db-tabs-buttons-wrapper-t2">
            {uploadInfo.map((info, i) => (
              <span
                onClick={() => {
                  setDataInfo(uploadInfo[i]);
                  setGraphValues(uploadInfo[i].countInfo);
                  setSelectedOpt(uploadInfo[i].type);
                }}
                className={selectedOpt === info.type ? "activateLink" : ""}
              >
                {info.type}
              </span>
            ))}
          </div>
        )}
      </div>
      {uploadInfo && !_.isEmpty(graphValues) ? <Pie {...config} /> : <Loader />}
      <Row className="legend-wrapper" gutter={[18, 12]}>
        {dataInfo &&
          dataInfo.data &&
          dataInfo.data.map((d) => (
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={12}
              className="db-legend-t2"
            >
              <span className="inner-box">
                <Badge color={d.color} text={d.status} />
              </span>
              <span className="ant-badge-vale-text">
                {d.percent && !isNaN(d.percent) ? d.percent : 0}%
              </span>
            </Col>
          ))}
      </Row>
    </Card>
  );
};

export default PieChartOld;
