import { useEffect } from "react";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizations } from "../../actions/Creators";

const OrganizationDropdown = ({ setSelectedOrg }) => {
  const dispatch = useDispatch();
  const orgList = useSelector((state) => state.organizations.organizationsList);
  const defaultOrg = useSelector((state) => state.header.selectedOrganization);

  const { Option } = Select;
  useEffect(() => {
    dispatch(getOrganizations());
  }, []);

  let defaultSelectedOrg = orgList.find((org) => org.id == defaultOrg.id);

  return (
    <Select
      value={defaultSelectedOrg && defaultSelectedOrg.name}
      placeholder="Change Customer"
      showSearch={true}
      onChange={(val, options) => setSelectedOrg(options)}
    >
      {orgList &&
        orgList.map((option) => (
          <Option key={option.id} value={option.name}>
            {option.name.charAt(0).toUpperCase() + option.name.slice(1)}
          </Option>
        ))}
    </Select>
  );
};

export default OrganizationDropdown;
