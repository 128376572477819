import HttpRequest from "../../utils/httpRequests";
import endPoints from "../../utils/endPoints";

export const uploadData = ({ type, issue_date, effective_date, vendor, contract_id, contractTemplate }, data) => {
  return HttpRequest("post", `${endPoints.UPLOAD_DATA}?type=${type}&issue_date=${issue_date}&effective_date=${effective_date}&vendor=${vendor}&contract_id=${contract_id}&contractTemplate=${contractTemplate}`, data);
};

export const getContractUploader = () => {
  return HttpRequest("get", `${endPoints.GET_CONTRACT_UPLOADER}`);
};
