import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table, Form, Input } from "antd";
import Notification from "../../Common/Notification";
import { HIDE_NOTIFICATION } from "../../actions/Types";
import { actionDispatch, addPOs, getVendors } from "../../actions/Creators";
import VendorDropdown from "../../Common/VendorDropdown";
import NumberFormat from "react-number-format";
import { Badge, Space, Switch } from "antd";

const DataList = ({ files, removeFile }) => {
  const dispatch = useDispatch();
  const [uploadStatus, setUploadStatus] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const vendor = useSelector((state) => state.vendor);
  const notification = useSelector((state) => state.commonReducer.notification);
  const loading = useSelector((state) => state.commonReducer.loader);
  const organization = useSelector((state) => state.header.selectedOrganization);

  useEffect(() => {
    // dispatch(getVendors(organization.id));
  }, []);

  const onSaveFiles = async () => {
    setUploadStatus(true);
  };
  const columns = [
    {
      title: "File Name",
      dataIndex: "file_key",
      ellipsis: true,
      // width: 200,
    },
    {
      title: "No. of Rows",
      dataIndex: "rowsCount",
    },
    {
      title: "Duplicates",
      dataIndex: "duplicates",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Action",
      dataIndex: "remove",
      width: 100,
    },
  ];
  return (
    <>
      {notification.open && notification.message ? (
        <Notification
          msg={notification.message}
          type={notification.type}
          clearMsg={() => {
            dispatch(actionDispatch(HIDE_NOTIFICATION));
          }}
        />
      ) : null}
      <div className="table-file-uploaded">
        <Form onFinish={() => onSaveFiles()} validateTrigger="onBlur">
          <Table
            columns={columns}
            rowClassName="row-item"
            pagination={false}
            scroll={{ x: 800 }}
            dataSource={files.map((file, index) => {
              // file.organization_id = organization && organization.id;
              // file.user_id = user.id;
              // file.file_key = file.name;
              return {
                key: index,
                file_key: file.name,
                rowsCount: <p>{file?.data?.rowsCount}</p>,
                duplicates: <p>{file?.data?.duplicates || 0}</p>,
                status: (
                  <Space>
                    {/* <Badge count={show ? 25 : 0} /> */}
                    <Badge className="site-badge-count-109" count={"Success"} style={{ backgroundColor: "#52c41a" }} />
                  </Space>
                ),
                remove: <span title="Remove" className="icon-minus" onClick={() => removeFile(index)}></span>,
              };
            })}
          />
        </Form>
      </div>
    </>
  );
};

export default DataList;
