import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Row, Col, Form, Spin, Button } from "antd";
import { Filters } from "../../Reports/Filters";
import { useDispatch, useSelector } from "react-redux";
import {
  getVendors,
  getAllUsers,
  getOrganizations,
  getAggregateDiscrepanciesOfPoInvoice,
  getPoInvoiceCountAndStatus,
  getProcessedPOAndInvoice,
  getAllContracts,
  getProcessedContractsWithTimeDiff,
  getLoginFrequencyData,
  getProcessedAndRejectedPOInvoices,
  setFilterObject,
} from "../../actions/Creators";
import Content from "../../Layout/Content";
import ColumnChart from "../../Common/Charts/Column";
import ReportTiles from "../../Reports/ReportTiles";
import reportTemplates from "../../Constants/reportTemplates";
import { VIEW_REPORTS_ROUTE } from "../../Constants/routeNames";
import ReportCounter from "../../Reports/ReportCounters";
import { ROLES_DISCREPANCY_APPROVER_VIEW, ROLES_REPORT_VIEW } from "../../utils/constants";
import { isAuthorized } from "../../Services/Auth/auth";
import Authorized from "../../Role-Authorization/Authorized";

const ReportDashboard = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [reportTitle, setReportTitle] = useState("");
  const [filters, setFilters] = useState({});
  const organizations = useSelector((state) => state.organizations.organizationsList);
  const users = useSelector((state) => state.auth.users);
  const vendors = useSelector((state) => state.vendor.vendors);
  const report = useSelector((state) => state.reports.report);
  const rolesArr = useSelector((state) => state.auth.roles);
  const stateFilters = useSelector((state) => state.reports.filters);
  const reportIndex = useSelector((state) => state.reports.currentReportIndex);
  const isFileLoading = useSelector((state) => state.commonReducer.loader);
  const organization = useSelector((state) => state.header.selectedOrganization);
  const [reportData, setReportData] = useState(reportTemplates[reportIndex]);

  useEffect(() => {
    if (isAuthorized(ROLES_REPORT_VIEW, rolesArr)) {
      dispatch(getOrganizations());
    }
    setFilters({ ...filters, ...stateFilters });
  }, []);

  useEffect(() => {
    setFilters({ ...filters, ...stateFilters });
    form.setFieldsValue({ ...stateFilters });
  }, [form]);

  useEffect(() => {
    if (isAuthorized(ROLES_REPORT_VIEW, rolesArr)) {
      dispatch(getVendors(form.getFieldsValue().organization));
      dispatch(getAllUsers(form.getFieldsValue().organization));
    }
    if (isAuthorized(ROLES_DISCREPANCY_APPROVER_VIEW, rolesArr)) {
      dispatch(getVendors(organization && organization.id));
      dispatch(getAllUsers(organization && organization.id));
    }
  }, [form.getFieldsValue().organization]);

  useEffect(() => {
    form.getFieldsValue().vendor &&
      form.setFieldsValue({
        organization: vendors.find((vendor) => vendor.id === form.getFieldsValue().vendor).organization_id,
      });
    setFilters({
      ...filters,
      ...stateFilters,
      organization: form.getFieldsValue().organization && form.getFieldsValue().organization,
    });
  }, [form.getFieldsValue().vendor]);

  useEffect(() => {
    form.getFieldsValue().user &&
      form.setFieldsValue({
        organization: users.find((user) => user.id === form.getFieldsValue().user).organization_id,
      });
    setFilters({
      ...filters,
      ...stateFilters,
      organization: form.getFieldsValue().organization && form.getFieldsValue().organization,
    });
  }, [form.getFieldsValue().user]);

  useEffect(() => {
    let isRoleAuthorized = isAuthorized(ROLES_REPORT_VIEW, rolesArr);
    switch (reportData.label) {
      case "PO_INVOICE_COUNT_WITH_STATUS_REPORT":
        setReportTitle(filters.doc_type === "po" ? "Template not found for Purchase Orders" : filters.doc_type === "invoice" ? "Template not found for Invoices" : "Template not found for Purchase Orders and Invoices");
        return dispatch(getPoInvoiceCountAndStatus(filters, filters.doc_type, isRoleAuthorized, organization && organization.id));
      case "PROCESSED_PO_INVOICE_WITH_COUNT":
        setReportTitle(filters.doc_type === "po" ? "Processed Purchase Orders" : filters.doc_type === "invoice" ? "Processed Invoices" : "Processed Purchase Orders and Invoices");
        return dispatch(getProcessedPOAndInvoice(filters, filters.doc_type, isRoleAuthorized, organization && organization.id));
      case "TOTAL_NUMBER_OF_CONTRACTS":
        return dispatch(getAllContracts(filters, isRoleAuthorized, organization && organization.id));
      case "PROCESSED_CONTRACTS_WITH_TIME_DIFFERENCE":
        setReportTitle("Processed Contracts");
        return dispatch(getProcessedContractsWithTimeDiff(filters, isRoleAuthorized, organization && organization.id));
      case "LOG_IN_FREQUENCY":
        setReportTitle("Number of Login Attempts");
        return dispatch(getLoginFrequencyData(filters, isRoleAuthorized, organization && organization.id));
      case "APPROVED_REJECTED_PO_INVOICE_PERCENTAGE":
        setReportTitle(filters.doc_type === "po" ? "Approved/Rejected Purchase Orders" : filters.doc_type === "invoice" ? "Approved/Rejected Invoices" : "Approved/Rejected Purchase Orders and Invoices");
        return dispatch(getProcessedAndRejectedPOInvoices(filters, filters.doc_type, isRoleAuthorized, organization && organization.id));
      case "DISCREPANCY_AGGREGATE_PO":
        setReportTitle("Discrepancy Amount Aggregate for PO");
        return dispatch(getAggregateDiscrepanciesOfPoInvoice(filters, "po", isRoleAuthorized, organization && organization.id));
      case "DISCREPANCY_AGGREGATE_INVOICE":
        setReportTitle("Discrepancy Amount Aggregate for Invoice");
        return dispatch(getAggregateDiscrepanciesOfPoInvoice(filters, "invoice", isRoleAuthorized, organization && organization.id));
    }
  }, [reportData.label, filters]);

  const updateFilters = () => {
    setFilters({
      ...form.getFieldsValue(),
      startDate: form.getFieldsValue().date_range && moment(form.getFieldsValue().date_range[0]).format("YYYY-MM-DD"),
      endDate: form.getFieldsValue().date_range && moment(form.getFieldsValue().date_range[1]).format("YYYY-MM-DD"),
    });
    dispatch(
      setFilterObject({
        ...form.getFieldsValue(),
        startDate: form.getFieldsValue().date_range && moment(form.getFieldsValue().date_range[0]).format("YYYY-MM-DD"),
        endDate: form.getFieldsValue().date_range && moment(form.getFieldsValue().date_range[1]).format("YYYY-MM-DD"),
      })
    );
  };

  return (
    <>
      <ReportTiles reports={reportTemplates} setReportData={(report) => setReportData(report)} />

      <Content>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h3>{reportTitle}</h3>
          <Button className="btn-report-view">
            <Link to={`${VIEW_REPORTS_ROUTE}/${reportData.id}`}>View Detailed Report</Link>
          </Button>
        </div>

        <Form form={form} layout="vertical" onFieldsChange={updateFilters}>
          <Row gutter={[24, 0]} className="">
            <Filters filters={reportData.filters} organizations={organizations} organization={organization} vendors={vendors} users={users} isAuthorized={isAuthorized(ROLES_REPORT_VIEW, rolesArr)} />
            <Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
              <Button
                type="link"
                className="btb-clear-filter"
                onClick={() => {
                  dispatch(setFilterObject({}));
                  setFilters({});
                  form.resetFields();
                }}
                style={{ marginTop: "25px" }}
              >
                <span class="btn-icon icon-cross"></span> Clear Filters
              </Button>
            </Col>
          </Row>
        </Form>

        <hr className="breaker-t2" />
        <Row gutter={[24, 24]} className="mb-1">
          {/* <Authorized roleList={ROLES_REPORT_VIEW}> */}
          {/* Subuh asked to change role so that it should be visible */}
          {/* <Authorized roleList={ROLES_DISCREPANCY_APPROVER_VIEW}> */}
          <ReportCounter
            color={0}
            counter={{
              title: form.getFieldsValue().organization || organizations.length === 1 ? "Organization" : "Organizations",
              count: form.getFieldsValue().organization ? organizations.find((org) => org.id === form.getFieldsValue().organization).name : organizations.length,
            }}
          />
          {/* </Authorized> */}
          {/* <Authorized roleList={ROLES_DISCREPANCY_APPROVER_VIEW}> */}
          <ReportCounter
            color={0}
            counter={{
              title: "Organization",
              count: organization && organization.name,
            }}
          />
          {/* </Authorized> */}
          {reportData && !reportData.showUsers && (
            <ReportCounter
              color={1}
              counter={{
                title: form.getFieldsValue().vendor || vendors.length === 1 ? "Vendor" : "Vendors",
                count: form.getFieldsValue().vendor ? (vendors.find((vendor) => vendor.id === form.getFieldsValue().vendor) ? vendors.find((vendor) => vendor.id === form.getFieldsValue().vendor).title : "N/A") : vendors.length,
              }}
            />
          )}
          {reportData && reportData.showUsers && (
            <ReportCounter
              color={1}
              counter={{
                title: form.getFieldsValue().user || users.length === 1 ? "User" : "Users",
                count: form.getFieldsValue().user ? (users.find((user) => user.id === form.getFieldsValue().user) ? users.find((user) => user.id === form.getFieldsValue().user).first_name : "N/A") : users.length,
              }}
            />
          )}
          {reportData && Object.entries(reportData.counterFields(report, reportData.countTitle)).map(([key, value], index) => <ReportCounter counter={{ title: key, count: value }} color={(index + 1) % 2 !== 0 ? 0 : 1} />)}
        </Row>
        <Row gutter={[24, 24]} className="reports-chart-wrapper">
          {reportData &&
            reportData.graphs &&
            reportData.graphs.map((graph) => (
              <Col xl={12}>
                <Spin spinning={isFileLoading} style={{ width: "100%" }}>
                  <ColumnChart
                    dataSet={[
                      {
                        data: report && graph.dataFunction(report.rows),
                        xField: graph.xField,
                        yField: graph.yField,
                        interval: graph.interval,
                      },
                    ]}
                    labelText={graph.labelText}
                    formatter={graph.formatter ? graph.formatter : ""}
                  />
                </Spin>
              </Col>
            ))}
        </Row>
      </Content>
    </>
  );
};

export default ReportDashboard;
