import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";
import { Card, Badge, Row, Col, Typography } from "antd";
import { Column } from "@ant-design/charts";
import Loader from "../Loader";
import moment from "moment";
import Information from "../../assets/images/information-icon.svg";
import { VIEW_PO_COMPLIANCE_ROUTE } from "../../Constants/routeNames";

const ColumnChart = ({ breakHeader, dataSet, types, labelText = "" }) => {
  const history = useHistory();
  const { Title } = Typography;

  const [indexNo, setIndexNo] = useState(0);

  const [data, setData] = useState([]);
  const [selectedType, setSelectedType] = useState("");

  useEffect(() => {
    if (dataSet[0]?.data?.length) {
      const d = [...dataSet[0]?.data];
      setData(indexNo === 0 ? d.slice(0, 7) : d);
    }

    types && setSelectedType(types[0]);
  }, [types]);

  var config = {
    data: selectedType === "Amount" ? dataSet[0]?.data?.topAmount : selectedType === "Count" ? dataSet[0]?.data?.topCount : data,
    color: dataSet[0]?.color,
    xField: dataSet[indexNo].xField,
    yField: selectedType === "Amount" ? "totalamountwithdiscrepancy" : selectedType === "Count" ? "po_number_2023" : dataSet[indexNo].yField,
    seriesField: "",
    height: 300,
    maxColumnWidth: 20,
    yAxis: {
      nice: true, // Ensure that the axis scale is nice
      label: {
        formatter: (v, data, ok) => (Number(v) % 1 === 0 ? (selectedType === "Amount" ? `$${v}` : v) : ""),
      },
    },
    legend: false,
    tooltip: {
      formatter: (d) => {
        return { name: d?.citywithstate ? selectedType : "Contract Renewal", value: d?.po_number_2023 ? d?.po_number_2023 : d?.totalamountwithdiscrepancy ? `$${d?.totalamountwithdiscrepancy}` : d.count };
      },
    },
    onReady: (plot) => {
      plot.on("element:click", (...args) => {
        // search=Houston
        if (args[0] && args[0].data) {
          const plotData = args[0].data.data;
          let firstDay, lastDay;

          if (plotData.city) {
            history.push(`${VIEW_PO_COMPLIANCE_ROUTE}?search=${plotData?.city}`);
          } else {
            if (plotData.month === "Overdue") {
              const date = new Date(),
                y = date.getFullYear(),
                m = date.getMonth();
              firstDay = moment(new Date(2020, 1, 1)).format("YYYY-MM-DD");
              lastDay = moment(new Date(y, m, 0)).format("YYYY-MM-DD");
            } else {
              firstDay = moment(new Date(plotData.year, plotData.mon, 1)).format("YYYY-MM-DD");
              lastDay = moment(new Date(plotData.year, plotData.mon + 1, 0)).format("YYYY-MM-DD");
            }

            const vendorUrl = plotData.vendor === "0" ? "" : `&vendor=${plotData.vendor}`;
            history.push(`/contracts/view?startDate=${firstDay}&endData=${lastDay}${vendorUrl}`);
          }
        }
      });
    },
  };

  const LeftHeader = () => (
    <Title level={3}>
      {labelText} <img src={Information} alt="information-icon" />{" "}
    </Title>
  );

  const RightHeader = () => {
    return (
      types && (
        <div className="db-tabs-buttons-wrapper-t2 float-right m-0 ">
          {types.map((type, i) => (
            <span
              className={selectedType === type ? "activateLink" : ""}
              onClick={() => {
                if (dataSet[0]?.data?.length) {
                  const d = [...dataSet[0].data];
                  setData(i === 0 ? d.slice(0, 7) : d);
                }
                setSelectedType(type);
              }}
            >
              {type}
            </span>
          ))}
        </div>
      )
    );
  };
  <div className="db-legend-t1 ">{dataSet[indexNo] && dataSet[indexNo].legend && dataSet[indexNo].legend.map((t) => <Badge text={t.type} color={t.color} />)}</div>;

  return (
    <Card className="dashboad-chart-box dashboard-card-t1 ">
      {breakHeader ? (
        <>
          <Row className="mb-1">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              {LeftHeader()}
            </Col>
          </Row>
          <Row className="mb-1">
            <Col>{RightHeader()}</Col>
          </Row>
        </>
      ) : (
        <Row className="mb-1">
          <Col xs={12} sm={12} md={24} lg={24} xl={12} xxl={12}>
            {LeftHeader()}
          </Col>

          <Col xs={12} sm={12} md={24} lg={24} xl={12} xxl={12}>
            {RightHeader()}
          </Col>
        </Row>
      )}

      {dataSet && dataSet[indexNo] && !_.isEmpty(dataSet[indexNo].data) ? <Column {...config} /> : <Loader />}
      {breakHeader && (
        <p className="view-all">
          <Link to={`${VIEW_PO_COMPLIANCE_ROUTE}?type=${selectedType}&sort=desc`}>View All</Link>
        </p>
      )}
    </Card>
  );
};

export default ColumnChart;
