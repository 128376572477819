import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SidePanel from "../SidePanel";
import { Form, Input, Button, Typography, Row, Col, Checkbox } from "antd";
import { Link } from "react-router-dom";
import Notification from "../../../Common/Notification";
import { actionDispatch } from "../../../actions/Creators";
import { HIDE_NOTIFICATION } from "../../../actions/Types";
import PhoneInput from "react-phone-input-2";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from 'react-router-dom';
import { registerNewUser } from "../../../actions/Creators/Auth";
import { NEW_USER_UPLOAD_ROUTE } from "../../../Constants/routeNames";

const SignUp = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const notification = useSelector((state) => state.commonReducer.notification);
  const loading = useSelector((state) => state.commonReducer.loader);
  const [phoneNo, setPhoneNo] = useState({ country: "", phone: "" });
  const { Title } = Typography;
  const [form] = Form.useForm();

  const onSubmit = async (e) => {
    const response = await dispatch(
      registerNewUser({
      ...form.getFieldsValue(),
      phoneNo: phoneNo,
      })
    );
    
    if (response && response.success) {
      history.push({
        pathname: NEW_USER_UPLOAD_ROUTE,
        state: { user_id: response.data.id }
      });
    }
  };

  const options = [
    { label: "Email", value: "Email" },
    { label: "Phone", value: "Phone" },
    { label: "Text", value: "Text" },
  ];

  return (
    <SidePanel>
      <div className="login-form-wrapper">
        <Row align="middle" justify="center">
          <Col xxl={24} className="request-header">
            <Link to="/login" className="btn-request-back">
              <ArrowLeftOutlined />
            </Link>
            <Title level={2}>Register as a new user</Title>
          </Col>
        </Row>

        <Form
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          className="cc-form-group"
          validateTrigger="onBlur"
        >
          <Row align="middle" gutter={[16, 16]} justify="center">
            <Col lg={24} xl={12} xxl={12}>
              <Form.Item
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: "First name required",
                  },
                ]}
              >
                <Input placeholder="Enter First name" />
              </Form.Item>
            </Col>

            <Col lg={24} xl={12} xxl={12}>
              <Form.Item
                name="last_name"
                rules={[
                  {
                    required: true,
                    message: "Last name required",
                  },
                ]}
              >
                <Input placeholder="Enter Last name" />
              </Form.Item>
            </Col>
          </Row>

          <Row align="middle" justify="center" gutter={[16, 16]}>
            <Col lg={24} xl={24} xxl={24}>
              <Form.Item
                name="organization"
                rules={[
                  {
                    required: true,
                    message: "Name of Organization required",
                  },
                ]}
              >
                <Input placeholder="Enter your organization" />
              </Form.Item>
            </Col>
          </Row>

          <Row align="middle" gutter={[16, 16]} justify="center">
            <Col lg={24} xl={12} xxl={12}>
              <Form.Item
                name="phone_no"
                rules={[
                  {
                    required: true,
                    message: "Phone number required",
                  },
                ]}
              >
                <PhoneInput
                  inputClass="ant-input"
                  inputStyle={{
                    height: "36px",
                    width: "268px",
                  }}
                  country={"us"}
                  placeholder="Enter phone no."
                  onChange={(value, country, e, formattedValue) =>
                    setPhoneNo({
                      country: country.name,
                      phone: formattedValue,
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col lg={24} xl={12} xxl={12}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Email address required",
                  },
                ]}
              >
                <Input placeholder="Enter email" />
              </Form.Item>
            </Col>
          </Row>

          <Row align="middle" gutter={[16, 16]} justify="center">
            <Col lg={24} xl={24} xxl={24}>
              <Form.Item
                label="Method of communication"
                name="methodOfCommunication"
                rules={[
                  {
                    required: true,
                    message: "At least one field required",
                  },
                ]}
              >
                <Checkbox.Group options={options} />
              </Form.Item>
            </Col>
          </Row>

          <Row align="middle" gutter={[16, 16]} justify="center">
            <Col lg={24} xl={12} xxl={12}>
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Username required",
                  },
                ]}
              >
                <Input placeholder="Enter Username" />
              </Form.Item>
            </Col>

            <Col lg={24} xl={12} xxl={12}>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Password required",
                  },
                ]}
              >
                <Input placeholder="Enter Password" type="password" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button
              className="form_button btn-login"
              block
              htmlType="submit"
              style={{ marginTop: "10px" }}
              loading={loading}
            >
              Submit
            </Button>
          </Form.Item>
          {notification.open && notification.message ? (
            <Notification
              msg={notification.message}
              type={notification.type}
              clearMsg={() => {
                dispatch(actionDispatch(HIDE_NOTIFICATION));
              }}
            />
          ) : null}
        </Form>
      </div>
    </SidePanel>
  );
};
export default SignUp;
