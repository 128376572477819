import HttpRequest from "../../utils/httpRequests";
import endPoints from "../../utils/endPoints";

export const uploadContractDocs = (data) => {
  return HttpRequest("post", endPoints.UPLOAD_CONTRACTS_DOCS, data);
};

export const uploadInvoiceDocs = (data) => {
  return HttpRequest("post", endPoints.UPLOAD_INVOICES_DOCS, data);
};

export const uploadPODocs = (data) => {
  return HttpRequest("post", endPoints.UPLOAD_PO_DOCS, data);
};

export const getPoFileAPI = (key) => {
  return HttpRequest("get", `${endPoints.GET_PO_FILE}${key}`);
};

export const getContractFileAPI = (key) => {
  return HttpRequest("get", `${endPoints.GET_CONTRACT_FILE}${key}`);
};

export const getInvoiceFileAPI = (key) => {
  return HttpRequest("get", `${endPoints.GET_INVOICE_FILE_URL}${key}`);
};

export const uploadNewUserDocsAPI = (data) => {
  return HttpRequest("post", endPoints.UPLOAD_NEW_USER_DOCS, data);
};

export const createDocsFromUploadedFiles = (data) => {
  return HttpRequest("post", endPoints.CREATE_DOCS_FROM_UPLOADED_FILES, data);
}