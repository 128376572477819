import React from "react";
import { Typography, Card, Row, Col } from "antd";
import Loader from "../../Common/Loader";

const { Title } = Typography;

const SimpleCard = ({ svg, unit, title, boxClass }) => {
  return (
    <Card className={`dashboard-number-box dashboard-card-t1 ${boxClass}`}>
      <Row>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <div className="ico-box">{svg}</div>
        </Col>
        <Col xs={24} sm={24} md={18} lg={18} xl={18}>
          <div className="dnb-details">
            {unit || unit == 0 ? <Title>{unit}</Title> : <Loader />}
            <Title level={4}>{title}</Title>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default SimpleCard;
