import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table, Input, Radio, Row, Col, Form, Select, DatePicker } from "antd";
import { MailOutlined } from "@ant-design/icons";
import NoFilesChoosen from "../../Common/NoFilesChoosen/NoFilesChoosen";
import Notification from "../../Common/Notification";
import { HIDE_NOTIFICATION } from "../../actions/Types";
import { actionDispatch, createContracts, getVendors, fetchAllTemplates, fetchContractsById, getSelectedContractTemplate } from "../../actions/Creators";
import Uploader from "../../Common/Uploader";
import "./Contract.scss";
import VendorDropdown from "../../Common/VendorDropdown";

const UploadContract = () => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  useEffect(() => {
    dispatch(fetchAllTemplates());
  }, []);
  const acceptedFormats = ["pdf"];
  const setFilteredFiles = (files) => {
    setFiles(files.filter((file) => acceptedFormats.includes(file.name.split(".").reverse()[0])));
  };
  const onRemoveFile = (key) => {
    const remainingFiles = files.filter((file, index) => index !== key);
    setFiles(() => [...remainingFiles]);
  };
  return (
    <div>
      <Row className="content-header">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <h3>Upload Contract</h3>
          <Uploader files={files} setFiles={(files) => setFilteredFiles(files)} type="Contract files." accept={".pdf"} />
        </Col>
      </Row>
      {files.length > 0 ? <FileList files={files} setFiles={setFiles} removeFile={onRemoveFile} /> : <NoFilesChoosen />}
    </div>
  );
};

export default UploadContract;

const FileList = ({ files, removeFile }) => {
  const [uploadStatus, setUploadStatus] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const vendor = useSelector((state) => state.vendor);
  const notification = useSelector((state) => state.commonReducer.notification);
  const organization = useSelector((state) => state.header.selectedOrganization);
  const templates = useSelector((state) => state.templatesReducer.templates);

  useEffect(() => {
    dispatch(getVendors(organization.id));
  }, []);

  const onSaveFiles = async () => {
    setUploadStatus(true);
    dispatch(createContracts(files, user.id));
  };
  const columns = [
    {
      title: "File Name",
      dataIndex: "file_key",
      // ellipsis: true,
      width: 220,
    },
    {
      title: "Vendor Name*",
      dataIndex: "vendor_id",
      width: 160,
    },
    {
      title: "Contract Number",
      dataIndex: "contractNumber",
      width: 160,
    },
    {
      title: "Contract Type",
      dataIndex: "contractType",
      width: 160,
    },
    {
      title: "Category*",
      dataIndex: "template",
      width: 160,
    },
    {
      title: "Effective Date",
      dataIndex: "effectiveDate",
      width: 180,
    },
    {
      title: "Expiry Date",
      dataIndex: "expiryDate",
      width: 180,
    },
    {
      title: "Action",
      fixed: "right",
      align: "center",
      dataIndex: "remove",
      width: 80,
    },
  ];
  const loading = useSelector((state) => state.commonReducer.loader);
  return (
    <>
      {notification.open && notification.message ? (
        <Notification
          msg={notification.message}
          type={notification.type}
          clearMsg={() => {
            dispatch(actionDispatch(HIDE_NOTIFICATION));
          }}
        />
      ) : null}
      <div className="table-file-uploaded">
        <Form onFinish={onSaveFiles}>
          <Table
            scroll={{ x: 1300 }}
            columns={columns}
            rowClassName="row-item"
            pagination={false}
            dataSource={files.map((file, index) => {
              file.organization_id = organization && organization.id;
              file.user_id = user.id;
              file.file_key = file.name;
              file.title = file.name;
              // file.expiry = "2022-01-01";
              return {
                key: index,
                file_key: file.name,
                vendor_id: (
                  <VendorDropdown
                    vendor={vendor}
                    create={true}
                    index={index}
                    defaultValue={false}
                    getValue={(data) => {
                      file.vendorName = data.value;
                      file.vendor_id = data.id;
                    }}
                  />
                ),
                contractNumber: (
                  <Form.Item name={`contract number ${index}`}>
                    <Input len={3} onChange={(e) => (file.contract_number = e.target.value)} maxLength={20} />
                  </Form.Item>
                ),
                contractType: (
                  <Form.Item>
                    <Select defaultValue={false} onChange={(value, options) => (file.auto_renewal = value)}>
                      <Select.Option key="new" value={false}>
                        New
                      </Select.Option>
                      <Select.Option key="renewal" value={true} disabled={true}>
                        Renewal
                      </Select.Option>
                    </Select>
                  </Form.Item>
                ),
                template: (
                  <Form.Item
                    name={`template ${index}`}
                    rules={[
                      {
                        required: true,
                        message: `Please select template`,
                      },
                    ]}
                  >
                    <Select
                      onChange={(value, options) => {
                        file.contract_type = value;
                        file.template_id = options.key;
                      }}
                    >
                      {templates
                        .sort((a, b) => a.orderNo - b.orderNo)
                        .map((template) => (
                          <Select.Option disabled={template.isDisabled} key={template._id} value={template.type}>
                            {template.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                ),
                effectiveDate: (
                  <Form.Item name={`effective_date ${index}`} className="upload-datepicker">
                    <DatePicker placeholder="YYYY-MM-DD" onChange={(date, dateString) => (file.effective_date = moment(date).format("YYYY-MM-DD"))} />
                  </Form.Item>
                ),
                expiryDate: (
                  <Form.Item
                    className="upload-datepicker"
                    name={`expiry ${index}`}
                    rules={[
                      ({ getFieldsValue }) => ({
                        validator(_, value) {
                          if (getFieldsValue()[`effective_date ${index}`] && getFieldsValue()[`expiry ${index}`] && getFieldsValue()[`effective_date ${index}`].isSameOrAfter(getFieldsValue()[`expiry ${index}`].format("YYYY-MM-DD"))) {
                            return Promise.reject("Expiry date should be greater than Effective date.");
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <DatePicker placeholder="YYYY-MM-DD" onChange={(date, dateString) => (file.expiry = moment(date).format("YYYY-MM-DD"))} />
                  </Form.Item>
                ),
                remove: <span title="Remove" className="icon-minus" onClick={() => removeFile(index)}></span>,
              };
            })}
          />
          <Button htmlType="submit" className="btn-primary" loading={loading && uploadStatus}>
            <span className="btn-icon icon-add-new"></span> Upload Contracts
          </Button>
        </Form>
      </div>
      <div
        style={{
          float: "right",
          textDecoration: "underline",
        }}
      >
        <MailOutlined />
        <a href="mailto:xyz@gmail.com" style={{ marginLeft: "5px" }}>
          Request Contract Template
        </a>
      </div>
    </>
  );
};
