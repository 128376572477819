import { useState, useEffect } from "react";
import { AutoComplete, Select, Form } from "antd";
import { isEmpty } from "../../utils/isEmpty";

const VendorDropdown = ({
  vendor,
  getValue,
  index,
  id,
  setClearField = () => {},
  create = false,
  clearField = false,
  label = "",
  defaultValue = null,
  disabled = false,
  addAll = false
}) => {
  const { Option } = Select;
  const [text, setText] = useState(defaultValue);

  useEffect(() => {
    setText(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    let vendorObj;
    if (id) {
      vendorObj = vendor.vendors.find((vendor) => vendor.id === id);
    }
    vendorObj && setText(vendorObj.title);
  }, [id]);
  let optionList = addAll ? [{ id: '0', value: 'ALL' }]: [];
  vendor &&
    vendor.vendors.forEach((option) =>
      optionList.push({ id: option.id, value: option.title })
    );

  const onChangeHandler = (value, opt) => {
    if (!(isEmpty(opt.value) && isEmpty(opt.id) && opt.id !== 0)) getValue(opt);
    else getValue({ id: 0, value: value });
  };
  return create ? (
    <Form.Item
      name={`vendor ${index}`}
      label={label}
      rules={[
        {
          required: true,
          message: 'Please select vendor',
        },
      ]}
    >
      <AutoComplete
        style={{
          width: 200,
        }}
        options={optionList}
        defaultValue={defaultValue}
        placeholder="Vendors"
        onChange={onChangeHandler}
        filterOption={(inputValue, option) =>
          option.value.toUpperCase().indexOf(inputValue.toString().toUpperCase()) !== -1
        }
        disabled={disabled}
      />
    </Form.Item>
  ) : (
    <Select
      disabled={disabled}
      placeholder="Vendors"
      showSearch={true}
      onChange={(value, opt) => {
        setClearField();
        getValue(opt);
        setText(value);
      }}
      value={clearField ? null : text}
    >
      {optionList.map((option) => (
        <Option key={option.id} value={option.value}>
          {option.value}
        </Option>
      ))}
    </Select>
  );
};

export default VendorDropdown;
