import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Badge, Tooltip, Spin } from "antd";
import { Link } from "react-router-dom";
import history from "../../History/history";
import Authorized from "../../Role-Authorization/Authorized";
import {
  EDIT_TEMPLATED_CONTRACT,
  ADD_INVOICE_ROUTE,
  INVOICE_DISCREPANCY_APPROVER_ROUTE,
  VIEW_TEMPLATED_CONTRACT,
  PO_DISCREPANCY_APPROVER_ROUTE,
  ADD_PO_ROUTE,
} from "../../Constants/routeNames";
import {
  getSelectedContractTemplate,
  deleteInvoice,
} from "../../actions/Creators";
import {
  ROLES_TRUPACTA_TEAM_VIEW,
  ROLES_INVOICE_ADD_VIEW,
  ROLES_DISCREPANCY_APPROVER_VIEW,
  ROLES_CONTRACTS_INVOICE_MANANGER,
  ROLES_PO_ADD_VIEW,
  ROLES_CONTRACT_UPLOAD_MANAGER,
  ROLES_INVOICE_MANAGER,
} from "../../utils/constants";
import {
  Status,
  badgeColor as contractBadgeColor,
} from "../Contracts/ContractStatus";
import Modal from "../../Common/Modal";
import { badgeColor as invoiceBadgeColor } from "../Invoice/InvoiceStatus";
import { badgeColor as POBadgeColor } from "../PO/POStatus";
import { dateToLocal } from "../../utils/moment";

export const ContractsColumn = (setFieldFunction = () => {}) => {
  const dispatch = useDispatch();
  return [
    {
      title: "Added On",
      dataIndex: "createdAt",
      render: (date) => dateToLocal(date),
      width: 200,
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      render: (vendor) => vendor && vendor.title,
      ellipsis: true,
      width: 200,
      className: "bold-im",
    },
    {
      title: "Contract No.",
      dataIndex: "contract_number",
      ellipsis: true,
      width: 150,
    },
    {
      title: "Contract Title",
      dataIndex: "title",
      ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 180,
      align: "left",
      render: (text) => {
        let color;
        for (let prop in contractBadgeColor)
          if (prop === text) {
            color = contractBadgeColor[prop];
          }
        return (
          <div>
            <Badge color={color} />
            {text.charAt(0).toUpperCase() + text.slice(1)}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "view",
      width: 80,
      align: "right",
      render: (text, row) => (
        <Row align="middle" justify="left">
          <Authorized roleList={[...ROLES_TRUPACTA_TEAM_VIEW]}>
            <Col span={12}>
              <Tooltip title="View Contract">
                <span
                  className="icon-row-view clr-link"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (row.status == "pending") {
                      setFieldFunction(row.id, row.template_id);
                      history.push(
                        `/contract/${row.id}/template/${row.template_id}`
                      );
                    } else {
                      history.push(
                        EDIT_TEMPLATED_CONTRACT.replace(":id", row.id)
                      );
                    }
                  }}
                ></span>
              </Tooltip>
            </Col>
          </Authorized>
          <Authorized
            roleList={[
              ...ROLES_CONTRACTS_INVOICE_MANANGER,
              ...ROLES_CONTRACT_UPLOAD_MANAGER,
            ]}
            shouldNotIncludePersona={ROLES_TRUPACTA_TEAM_VIEW}
          >
            {row.status === Status.PROCESSED && (
              <Col span={12}>
                <Tooltip title="View Contract">
                  <span
                    className="icon-row-view clr-link"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push(
                        VIEW_TEMPLATED_CONTRACT.replace(":id", row.id)
                      );
                    }}
                  ></span>
                </Tooltip>
              </Col>
            )}
          </Authorized>
        </Row>
      ),
    },
  ];
};

export const InvoicesColumns = () => {
  const invoice = useSelector((state) => state.invoice);
  const dispatch = useDispatch();
  const organization = useSelector(
    (state) => state.header.selectedOrganization
  );

  const [isModalOpen, setIsOpenModal] = useState({
    state: false,
    id: null,
  });

  const onRemove = async (id) => {
    dispatch(deleteInvoice(id, invoice.invoices, organization.id, true));
    setIsOpenModal({ state: false, id: null });
  };
  return [
    {
      title: "Added On",
      dataIndex: "createdAt",
      render: (date) => dateToLocal(date),
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      render: (vendor) => vendor && vendor.title,
      className: "bold-im",
    },
    {
      title: "Invoice No.",
      dataIndex: "invoice_number",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => {
        let color;
        for (let prop in invoiceBadgeColor)
          if (prop === text) {
            color = invoiceBadgeColor[prop];
          }
        return (
          <div>
            <Badge color={color} />
            {text.charAt(0).toUpperCase() + text.slice(1)}
          </div>
        );
      },
    },
    {
      title: " ",
      dataIndex: "view",
      width: 126,
      align: "right",
      render: (text, row) => {
        return (
          <Row align="middle" justify="left">
            {false && (
              <Modal
                isOpen={isModalOpen.state}
                handleOk={() => onRemove(isModalOpen.id)}
                setParentClose={() =>
                  setIsOpenModal({ state: false, id: null })
                }
              >
                Are you sure you want to delete this contract?
              </Modal>
            )}
            <Authorized roleList={ROLES_DISCREPANCY_APPROVER_VIEW}>
              <Col span={8}>
                <Tooltip title="View Discrepancy Invoice">
                  <Link
                    className="clr-link "
                    to={`${INVOICE_DISCREPANCY_APPROVER_ROUTE}${row.id}`}
                  >
                    <span className="icon-row-view"></span>
                  </Link>
                </Tooltip>
              </Col>
            </Authorized>
            <Authorized
              roleList={[...ROLES_INVOICE_ADD_VIEW, ...ROLES_INVOICE_MANAGER]}
            >
              <Col span={8}>
                <Tooltip title="View Invoice">
                  <Link
                    className="clr-link "
                    to={`${ADD_INVOICE_ROUTE}${row.id}`}
                  >
                    <span className="icon-row-view"></span>
                  </Link>
                </Tooltip>
              </Col>
            </Authorized>
          </Row>
        );
      },
    },
  ];
};

export const POColumns = () => {
  const dispatch = useDispatch();

  return [
    {
      title: "Added On",
      dataIndex: "createdAt",
      render: (date) => dateToLocal(date),
      width: 180,
    },
    {
      title: "PO No.",
      dataIndex: "po_number",
      render: (po_number) => (po_number ? po_number : "N/A"),
      ellipsis: true,
      width: 180,
    },
    {
      title: "Contract Associated",
      dataIndex: "contract_associate",
      ellipsis: true,
      render: (contract_associate) =>
        contract_associate ? contract_associate : "N/A",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (amount) =>
        amount || amount == 0
          ? `$${amount
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
          : "N/A",
      width: 180,
      className: "bold-im",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 240,
      render: (text) => {
        let color;
        for (let prop in POBadgeColor)
          if (prop === text) {
            color = POBadgeColor[prop];
          }
        return (
          <div style={{ textTransform: "capitalize" }}>
            <Badge color={color} />
            {text}
          </div>
        );
      },
    },
    {
      title: " ",
      dataIndex: "view",
      width: 50,
      align: "center",
      fixed: "right",
      align: "left",
      render: (text, row) => {
        return (
          <Row align="middle" justify="left">
            <Authorized roleList={ROLES_DISCREPANCY_APPROVER_VIEW}>
              <Col span={8}>
                <Tooltip title="View Discrepancy PO">
                  <Link
                    className="clr-link"
                    to={`${PO_DISCREPANCY_APPROVER_ROUTE}${row.id}`}
                  >
                    <span className="icon-row-view"></span>
                  </Link>
                </Tooltip>
              </Col>
            </Authorized>
            <Authorized roleList={ROLES_PO_ADD_VIEW}>
              <Col span={8}>
                <Tooltip title="View PO">
                  <Link className="clr-link" to={`${ADD_PO_ROUTE}${row.id}`}>
                    <span className="icon-row-view"></span>
                  </Link>
                </Tooltip>
              </Col>
            </Authorized>
          </Row>
        );
      },
    },
  ];
};
